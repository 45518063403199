/**
 * 政策AMを表現する資産型とベンチマーク（市場ベンチマーク （or 標準プロダクト？））のマッピングおよび、
 * 代替プロダクト設定時の資産型に対応する代替プロダクト候補のマッピング（これも市場ベンチマークのみ？ （標準プロダクトも含む？））
 *
 *
 * TODO: figmaプロトを見ると、ファクター的な要素まで資産型として定義されているように見える ⇒ 要確認
 * TODO: 資産型BM定義としては、プロダクト側は市場ベンチマークだけがリストされる？
 *  だが、代替プロダクト設定のときにも使われる、という話だった。
 *  代替プロダクト設定のときには市場ベンチマーク以外の、通常のプロダクトも並ぶはず。
 *  だとすると、通常のプロダクトもここにリストされてマッピング定義する？
 *  だとすると、プロダクト - 資産型定義とは異なるマッピング定義をすることになる？
 *  つまり、ベンチマークとして使う場合の資産型とプロダクト（ベンチマークプロダクト）のマッピングと、
 *  ポートフォリオ登録する際の資産型とプロダクトのマッピングは同じプロダクトでも違うマッピングをする可能性がある？
 *
 * スーパー複雑でわかりずらいけど、そういう理由はなく、一つのプロダクトに対しては一つの資産型マッピングしかないのであれば、
 * 一つのマッピング画面で定義した方がわかりやすい。
 *
 * また、どの場面でどのマッピングが使われるのか、代替プロダクトとして選ばれるプロダクト（BM）、
 * ポートフォリオ構成用プロダクトとして使われるプロダクトの概念をきちんと整理して定義すべき。
 *
 */

import {
  addProductAssetTypePair,
  getAssetNamingByCustomerId,
  removeProductAssetTypePair,
} from "api/Customer";
import {
  getBenchmarksAssetTypeMapByCustomerId,
  getProductsAssetTypeMapByCustomerId,
} from "api/ProductMaster";
import { h2z } from "utils/StringUtils";

import {
  NotosansjpNormalNavy10px,
  NotosansjpNormalNavy12px,
} from "../../styledMixins";

import { useEffect, useState } from "react";
import styled from "styled-components";

const assetColors = {
  国内債券: "169,211,143",
  国内株式: "97,159,232",
  外国債券: "244,159,79",
  外国株式: "211,85,85",
  オルタナティブ: "233,233,233",
  マルチアセット: "103,103,103",
  一般勘定: "25,46,85",
  短期資産: "143,141,239",
  ヘッジ外国債券: "248,203,173",
  債券: "226,239,218",
  グローバル債券: "226,239,218",
  ヘッジ外国株式: "255, 213, 213",
  株式: "217,225,242",
  グローバル株式: "217,225,242",
  ヘッジファンド: "234,149,234",
  プライベートアセット: "208,208,245",
  不動産: "255, 230, 153",
  インフラ: "191,143,0",
  プライベートエクイティ: "114, 114, 226",
  その他1: "204,255,204",
  その他2: "204,230,255",
  その他3: "255,230,204",
  その他4: "255,204,204",
  その他5: "230,204,255",
  その他6: "255,204,255",
  その他7: "255,204,230",
  その他8: "255,255,204",
};

const assetAlpha = {
  マルチアセット: 0.5,
};

const assetFontColors = {
  一般勘定: "#ffffff",
  マルチアセット: "#ffffff",
};

/*
 * @param {*} props
 * @returns
 */
const CustomerAssetBMProductMapping = (props) => {
  const { customerId, title, useBenchmark, useProduct } = props;
  const [assetNaming, setAssetNaming] = useState({});
  const [products, setProducts] = useState([]);
  const [defaultAssetTypes, setDefaultAssetTypes] = useState(new Map());
  const [keyword, setKeyword] = useState("");
  const [sortBy, setSortBy] = useState("alphabetical-ascend");
  const [selectingProductId, setSelectingProductId] = useState(null);
  const [shouldReload, setShouldReload] = useState(0);

  const checkBoxes = [
    { label: "名前昇順", value: "alphabetical-ascend" },
    { label: "名前降順", value: "alphabetical-descend" },
    { label: "デフォルト資産型昇順", value: "default-asset-type-ascend" },
    { label: "デフォルト資産型降順", value: "default-asset-type-descend" },
  ];

  useEffect(() => {
    if (customerId === null || customerId === undefined) return;
    getAssetNamingByCustomerId(customerId).then((assetNaming) => {
      setAssetNaming(assetNaming);
    });
    if (useBenchmark) {
      getBenchmarksAssetTypeMapByCustomerId(customerId).then(
        ([benchmarks, defaultAssetType]) => {
          setProducts(benchmarks);
          setDefaultAssetTypes(defaultAssetType);
        }
      );
      return;
    }
    if (useProduct) {
      getProductsAssetTypeMapByCustomerId(customerId).then(
        ([products, defaultAssetType]) => {
          setProducts(products);
          setDefaultAssetTypes(defaultAssetType);
        }
      );
      return;
    }
  }, [customerId, shouldReload]);

  const generateMouseEnterHandler = (productId) => {
    return (e) => setSelectingProductId(productId);
  };
  const generateMouseLeaveHandler = (productId) => {
    return (e) => setSelectingProductId(null);
  };

  const generateOnChangeHandler = (productId, asset) => {
    return async () => {
      const params = {
        assetType: asset,
        clientId: customerId,
        productId: productId,
      };
      for (const pd of products) {
        if (pd.id == productId) {
          for (const attr of pd.asset_type_attributes) {
            if (attr.asset_type == asset) {
              await removeProductAssetTypePair(params);
              setShouldReload(shouldReload + 1);
              return;
            }
          }
        }
      }
      await addProductAssetTypePair(params);
      setShouldReload(shouldReload + 1);
    };
  };

  const changeKeywordHandler = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const generateRadioButtonChangeHandler = (value) => {
    return (e) => {
      e.preventDefault();
      setSortBy(value);
    };
  };

  const sort = (list) => {
    switch (sortBy) {
      case "alphabetical-ascend":
        return list.sort((a, b) =>
          a.product_name.localeCompare(b.product_name)
        );
      case "alphabetical-descend":
        return list.sort((a, b) =>
          b.product_name.localeCompare(a.product_name)
        );
      case "default-asset-type-ascend":
        return list.sort((a, b) =>
          defaultAssetTypes
            .get(a.id)[0]
            .localeCompare(defaultAssetTypes.get(b.id)[0])
        );
      case "default-asset-type-descend":
        return list.sort((a, b) =>
          defaultAssetTypes
            .get(b.id)[0]
            .localeCompare(defaultAssetTypes.get(a.id)[0])
        );
      default:
        return list;
    }
  };

  return (
    <>
      <div
        style={{
          color: "#192e55",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          height: "calc(100vh - 280px)",
        }}
      >
        <div>{title}</div>
        <SearchForm>
          <SearchFrame>
            <SearchField
              placeholder="Search keywords here"
              value={keyword}
              onChange={changeKeywordHandler}
            />
            <SearchButton />
          </SearchFrame>
          <SearchOptionButtons>
            {checkBoxes.map((checkBox, i) => (
              <div key={checkBox.value}>
                <RadioButton
                  name="sort-order"
                  id={checkBox.value}
                  value={checkBox.value}
                  type="radio"
                  checked={sortBy === checkBox.value}
                  onChange={generateRadioButtonChangeHandler(checkBox.value)}
                />
                <RadioButtonLabel
                  htmlFor={checkBox.value}
                  key={checkBox.value}
                  className={checkBox.value === sortBy ? "checked" : ""}
                  onClick={generateRadioButtonChangeHandler(checkBox.value)}
                >
                  {checkBox.label}
                </RadioButtonLabel>
              </div>
            ))}
          </SearchOptionButtons>
        </SearchForm>
        <Table>
          <thead>
            <tr>
              <th>プロダクト名</th>
              {Object.keys(assetNaming)?.map((asset) => (
                <th
                  key={asset}
                  style={{
                    textAlign: "center",
                    fontSize:
                      (assetNaming.hasOwnProperty(asset)
                        ? assetNaming[asset]
                        : asset
                      ).length > 10
                        ? 9
                        : 12,
                  }}
                >
                  {assetNaming.hasOwnProperty(asset)
                    ? assetNaming[asset]
                    : asset}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sort(products).map((product, i) => {
              const productId = product.id;
              if (
                keyword.length >= 0 &&
                h2z(product.product_name).indexOf(keyword) < 0
              ) {
                return null;
              }
              return (
                <tr key={`${productId}-${i}`}>
                  <th
                    style={{
                      verticalAlign: "middle",
                      backgroundColor:
                        selectingProductId === productId
                          ? "rgba(183,234,255,0.8)"
                          : "transparent",
                    }}
                  >
                    <div
                      style={{
                        width: 250,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {h2z(product.product_name)}
                    </div>
                  </th>
                  {Object.keys(assetNaming)?.map((asset) => (
                    <td
                      key={`${productId}-${i}-${asset}`}
                      style={{ paddingTop: i === 0 ? 13 : 0 }}
                    >
                      <CheckBox
                        asset={asset}
                        value={`${productId}-${i}-${asset}`}
                        checked={product.asset_type_attributes.some(
                          (attr) => attr.asset_type === asset
                        )}
                        onChange={generateOnChangeHandler(productId, asset)}
                        label={
                          assetNaming.hasOwnProperty(asset)
                            ? assetNaming[asset]
                            : asset
                        }
                        onMouseEnter={generateMouseEnterHandler(productId)}
                        onMouseLeave={generateMouseLeaveHandler(productId)}
                        defaultCheck={defaultAssetTypes
                          .get(product.id)
                          ?.some((asset_type) => asset_type === asset)}
                      />
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

const CheckBoxFrame = styled.div`
  width: 120px;
  height: 30px;
  font-size: 12px;
  box-sizing: border-box;
  position: relative;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 30px;
    box-sizing: content-box;
    position: absolute;
    top: -2px;
  }

  &.checked:hover > div {
    background-color: #b7eaff;
    border: 2px solid #92e0ff;
    /* width: 124px; */
    top: -4px;
    left: -4px;
    box-shadow: 2px 2px 6px 0 rgb(0, 0, 0, 0.15);
  }
  &.unchecked:hover > div {
    background-color: #b7eaff;
    border: 2px solid #92e0ff;
    /* width: 124px; */
    top: -4px;
    left: -4px;
    box-shadow: 2px 2px 6px 0 rgb(0, 0, 0, 0.15);
  }
  &.checked:hover {
    border: 2px solid #92e0ff;
  }
  &.unchecked:hover {
    border: 2px solid #92e0ff;
  }

  & input[type="checkbox"] {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
  }

  & label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  &.checked:hover label {
    background-color: #b7eaff;
    color: #192e55;
  }
  &.unchecked:hover label {
    background-color: #b7eaff;
    color: #192e55;
  }

  /* &.unchecked label {
    background-color: white;
  }
  &.checked label {
    background-color: blue;
  } */
`;

const CheckBox = (props) => {
  const {
    value,
    asset,
    checked,
    onChange,
    label,
    defaultCheck,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const backgroundColor =
    !defaultCheck && !checked
      ? "white"
      : defaultCheck
      ? `rgba(${assetColors[asset]},1.0)`
      : checked
      ? `rgba(${assetColors[asset]},${assetAlpha[asset] || 0.3})`
      : "blue";

  const checkColor = checked
    ? `rgba(255,255,255,0.7)`
    : `rgba(255,255,255,0.0)`;
  const borderColor =
    !defaultCheck && !checked
      ? "rgba(229,236,237,0.5)"
      : defaultCheck || checked
      ? `rgba(${assetColors[asset]},1.0)`
      : "blue";
  const color = !defaultCheck && !checked ? "#E5ECED" : "#192E55";
  return (
    <CheckBoxFrame
      className={
        defaultCheck ? "defaultCheck" : checked ? "checked" : "unchecked"
      }
      style={{
        backgroundColor: backgroundColor,
        border: `2px solid ${borderColor}`,
        color: color,
        cursor: defaultCheck ? "default" : "pointer",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div>
        {defaultCheck ? (
          <div
            style={{
              width: 120,
              height: 30,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              color: asset in assetFontColors ? assetFontColors[asset] : color,
              fontSize: label.length > 10 ? 9 : 12,
            }}
          >
            {label}
          </div>
        ) : (
          <>
            {checked ? (
              <CheckedCheckBox
                borderColor={borderColor}
                backgroundColor={checkColor}
                onClick={onChange}
              />
            ) : (
              <UncheckedCheckBox
                borderColor={borderColor}
                backgroundColor={backgroundColor}
                onClick={onChange}
              />
            )}
            <input
              type="checkbox"
              value={value}
              checked={checked}
              onChange={onChange}
            />
            <label
              htmlFor={value}
              onClick={onChange}
              style={{
                cursor: defaultCheck ? "default" : "pointer",
                fontSize: label.length > 10 ? 9 : 12,
              }}
            >
              {label}
            </label>
          </>
        )}
      </div>
    </CheckBoxFrame>
  );
};

const UncheckedCheckBox = (props) => {
  const { backgroundColor, borderColor, onClick } = props;

  return (
    <UncheckedCheckBoxSVG
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: 2 }}
      onClick={onClick}
    >
      <rect
        x="1.85742"
        y="1"
        width="12"
        height="12"
        rx="2"
        stroke={borderColor}
        fill={backgroundColor}
        strokeWidth="2"
      />
    </UncheckedCheckBoxSVG>
  );
};

const UncheckedCheckBoxSVG = styled.svg`
  .unchecked:hover & rect {
    fill: #b7eaff;
    stroke: #92e0ff;
  }
`;

const CheckedCheckBoxSVG = styled.svg`
  .checked:hover & rect {
    fill: #92e0ff;
  }
  .checked:hover & path {
    stroke: #b7eaff;
  }
`;

const CheckedCheckBox = (props) => {
  const { backgroundColor, borderColor, onClick } = props;

  return (
    <CheckedCheckBoxSVG
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: 2 }}
      onClick={onClick}
    >
      <rect x="0.857422" width="14" height="14" rx="3" fill={borderColor} />
      <path
        d="M4.00781 7.81673L5.75781 9.56673L11.5911 3.7334"
        stroke={backgroundColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </CheckedCheckBoxSVG>
  );
};

const Table = styled.table`
  position: relative;
  height: 100%;
  display: block;
  overflow-y: scroll;
  & th,
  & td {
    font-size: 12px;
    padding: 5px;
  }
  & thead th {
    background-color: #ffffff;
    position: sticky;
    text-align: left;
    top: 0;
    border-bottom: 1px solid #dddcdb;
    z-index: 1000;
  }
  & tbody {
    overflow-y: scroll;
  }
  & tbody td {
    text-align: center;
  }
  & tbody th {
    text-align: left;
  }
`;
const SearchForm = styled.div`
  width: 700px;
  height: 100px;
  display: flex;
  flex-direction: column;
`;

const SearchFrame = styled.div`
  margin-top: 16px;
  width: 700px;
  height: 30px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  background-color: #e5eced;
`;
const SearchField = styled.input`
  ${NotosansjpNormalNavy12px}
  /* width: 248px; */
  flex-grow: 1;
  height: 18px;
  margin-left: 12px;
  border: none;
  outline: unset;
  background-color: transparent;
  &::placeholder {
    color: #9fa0a0;
  }
`;
const SearchButton = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(/img/magnifier.svg);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;

  margin-right: 9px;
  border: none;
`;

const SearchOptionButtons = styled.div`
  margin-top: 7px;
  /* width: 300px; */
  height: 20px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 5px;
`;

const RadioButton = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
`;

const RadioButtonLabel = styled.label`
  ${NotosansjpNormalNavy10px}
  display: flex;
  align-items: center;
  color: #192e55;
  border-radius: 10px;
  border: 1px solid #dddcdb;
  /* transition: all 0.2s; */
  height: 20px;
  text-align: center;
  padding: 3px 12px;
  white-space: nowrap;
  cursor: pointer;
  &.checked {
    background: #d2f2ff;
    border: 1px solid #92e0ff;
  }
`;
export default CustomerAssetBMProductMapping;
