import { useAuthenticator } from "@aws-amplify/ui-react";
// import assetColorMap, { assetTypeClassNameMap } from "components/AssetColorMap";
import {
  getCustomer,
  getCustomers,
  getPAM,
  getPAMListByCustomerId,
} from "api/Customer";
import { getAssetTypes, getFactorVersions } from "api/ProductMaster";
import {
  createWorkspace,
  duplicateWorkspace,
  getWorkspace,
  getWorkspacePropertiesListByCustomerId,
} from "api/Workspace";
import { assetColors } from "components/AssetColorMap";

import { getAssetNamingByCustomerId } from "api/Customer";

import { forwardRef, useEffect, useRef, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import WSFrame from "components/WSFrame";
import YearRevisionVersionSelector from "components/YearRevisionVersionSelector";
import { PenEditIcon, TrashIcon } from "utils/SVGIcons";

import {
  NotosansjpMediumCloudBurst12px,
  NotosansjpMediumCloudBurst20px,
  NotosansjpMediumWhite12px,
  NotosansjpNormalCloudBurst10px,
  NotosansjpNormalCloudBurst12px,
  NotosansjpNormalNavy10px,
  NotosansjpNormalNavy12px,
  NotosansjpNormalNavy13px,
  ValignTextMiddle,
} from "../../styledMixins";

const getData = async (location, preConfigMode) => {
  const path = preConfigMode
    ? "/workspace/:workspaceId/properties/edit/customer/:customerId/pam/:pamId"
    : "/workspace/:workspaceId/properties/edit";

  const match = matchPath(path, location.pathname);
  const workspaceId = match?.params?.workspaceId;
  const assetTypes = await getAssetTypes();
  const factorYearRevisionVersions = await getFactorVersions();
  console.log("factorYearRevisionVersions", factorYearRevisionVersions);

  if (preConfigMode) {
    const customerId = match?.params?.customerId;
    const customer = await getCustomer(customerId);
    const pamId = match?.params?.pamId;
    const pam = await getPAM(pamId);

    return {
      wsProperties: {
        id: 0,
        customer: customer.customerName,
        customerName: customer.customerName,
        customerId: customer.customerId,
        ws: null,
        am: pam.name,
        dept: { department: null, division: null },
        assetMixId: pam.id,
        freezed: false,
        freezeDate: null,
      },
      factorYearRevisionVersions,
      assetTypes,
    };
  } else {
    if (workspaceId === "new") {
      return {
        wsProperties: {
          id: 0,
          customer: null,
          customerName: null,
          customerId: null,
          ws: null,
          am: null,
          dept: { department: null, division: null },
          assetMixId: null,
          freezed: false,
          freezeDate: null,
        },
        factorYearRevisionVersions,
        assetTypes,
      };
    }
    const wsProperties = await getWorkspace(workspaceId);

    return {
      wsProperties: { ...wsProperties, ws: `copy of ${wsProperties.ws}` },
      duplicationMode: true,
      originalWSId: workspaceId,
      factorYearRevisionVersions,
      assetTypes,
    };
  }
};

const WSProperties = (props) => {
  const { preConfigMode = false } = props;
  const [wsProperties, setWsProperties] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [ws, setWs] = useState("");
  const [am, setAm] = useState(null);
  const [initAmId, setInitAmId] = useState(null);
  const [assetNaming, setAssetNaming] = useState({});
  const [factorYearRevisionVersions, setFactorYearRevisionVersions] =
    useState(null);
  const [yearRevisionVersion, setYearRevisionVersion] = useState(null);
  // const { preConfigCustomerDone, setPreConfigCustomerDone } = useState(false);
  // const { preConfigPamDone, setPreConfigPamDone } = useState(false);

  const [customerCompleted, setCustomerCompleted] = useState(false);
  const [wsCompleted, setWsCompleted] = useState(false);
  const [amCompleted, setAmCompleted] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [duplicationMode, setDuplicationMode] = useState(false);
  const [originalWSId, setOriginalWSId] = useState(null);
  const [assetTypes, setAssetTypes] = useState();

  const { user } = useAuthenticator((context) => [context.user]);
  const userId = user.attributes.sub;

  const location = useLocation();
  useEffect(() => {
    if (customers === null || customers.length <= 0) return;
    getData(location, preConfigMode).then((res) => {
      setFactorYearRevisionVersions(res.factorYearRevisionVersions);
      setYearRevisionVersion(res.factorYearRevisionVersions[0]);
      const wsp = res.wsProperties;
      setAssetTypes(res.assetTypes);
      setDuplicationMode(res.duplicationMode);
      setOriginalWSId(res.originalWSId);

      setWsProperties(wsp);
      setCustomer({ customerName: wsp.customer, customerId: wsp.customerId });
      if ((preConfigMode || res.duplicationMode) && wsp.customerId) {
        setCustomerCompleted(true);
      }
      setWs(wsp.ws);
      // setAm(wsp.am);
      setInitAmId(wsp.assetMixId);
      if ((preConfigMode || res.duplicationMode) && wsp.am) {
        setAmCompleted(true);
      }
    });
  }, [customers]);

  useEffect(() => {
    getCustomers(userId).then((res) => {
      setCustomers(res.editableCustomers);
    });
  }, []);

  const [wsList, setWsList] = useState([]);
  useEffect(() => {
    if (
      customer === null ||
      customer?.customerId === null ||
      customer?.customerId === undefined
    )
      return;
    getWorkspacePropertiesListByCustomerId(customer?.customerId, userId).then(
      (res) => {
        setWsList(res);
      }
    );
    // getAssetNamingByCustomer(customer?.customerId).then((res) => {
    //   setAssetNaming(res);
    // });
    getAssetNamingByCustomerId(customer?.customerId).then((res) => {
      setAssetNaming(res);
    });
  }, [customer]);

  const wsNameList = wsList
    ? wsList.map(({ ws }) => ws).sort((a, b) => a.localeCompare(b))
    : [];
  const [ams, setAms] = useState([]);

  useEffect(() => {
    if (!initAmId || !ams || ams.length <= 0) return;
    const initAm = ams.find((am) => am.id === initAmId);
    setAm(initAm);
  }, [initAmId, ams]);

  useEffect(() => {
    if (customer && customer?.customerId) {
      getPAMListByCustomerId(customer?.customerId).then((res) => {
        setAms(res);
      });
    }
  }, [customer]);

  const step = !(customerCompleted && customer && customer?.customerName)
    ? 1
    : !(wsCompleted && ws && ws.length >= 0)
    ? 2
    : !(amCompleted && am && am.name.length >= 0)
    ? 3
    : 4;

  const allCompleted =
    step === 4 && customerCompleted && wsCompleted && amCompleted;
  const navigate = useNavigate();
  const gotoStep4 = (wsId) => {
    const step4Path = `/workspace/${wsId}/portfolio/edit`;
    navigate(step4Path);
  };
  const [customerForceCancel, setCustomerForceCancel] = useState(false);
  const [wsForceCancel, setWsForceCancel] = useState(false);
  const [amForceCancel, setAmForceCancel] = useState(false);

  const onClose = () => {
    setCustomerCompleted(false);
    setWsCompleted(false);
    setAmCompleted(false);
    setCustomerForceCancel(true);
    setWsForceCancel(true);
    setAmForceCancel(true);
  };

  useEffect(() => {
    if (customerCompleted) {
      setCustomerForceCancel(false);
    }
  }, [customerCompleted]);
  useEffect(() => {
    if (wsCompleted) {
      setWsForceCancel(false);
    }
  }, [wsCompleted]);
  useEffect(() => {
    if (amCompleted) {
      setAmForceCancel(false);
    }
  }, [amCompleted]);

  const customerStatus = customerCompleted ? "completed" : "active";
  const amStatus =
    !customerCompleted && !customer?.customerId
      ? "disabled"
      : amCompleted
      ? "completed"
      : "active";
  const wsStatus =
    !customerCompleted && !customer?.customerId
      ? "disabled"
      : wsCompleted
      ? "completed"
      : "active";

  if (yearRevisionVersion === null) return <></>;

  return (
    <WSFrame
      currentStep={step}
      step1Value={customerCompleted ? customer?.customerName : null}
      step2Value={wsCompleted ? ws : null}
      step3Value={amCompleted ? am?.name : null}
      factorYearRevisionVersion={yearRevisionVersion}
      mode="port"
    >
      <WSPropertiesFrame>
        <CustomerSelector
          initCustomer={customer}
          setCustomer={setCustomer}
          customers={customers}
          completed={customerCompleted}
          setCompleted={setCustomerCompleted}
          status={customerStatus}
          forceCanceled={customerForceCancel}
          duplicationMode={duplicationMode}
        />
        <WSNameSetter
          setWs={setWs}
          wsNames={wsNameList}
          completed={wsCompleted}
          setCompleted={setWsCompleted}
          customer={customer}
          status={wsStatus}
          forceCanceled={wsForceCancel}
          initName={ws}
          factorYearRevisionVersions={factorYearRevisionVersions}
          yearRevisionVersion={yearRevisionVersion}
          setYearRevisionVersion={setYearRevisionVersion}
        />
        <AMSelector
          initAmId={initAmId}
          ams={ams}
          setAm={setAm}
          assetNaming={assetNaming}
          completed={amCompleted}
          setCompleted={setAmCompleted}
          forceCanceled={amForceCancel}
          status={amStatus}
          assetTypes={assetTypes}
          duplicationMode={duplicationMode}
        />
      </WSPropertiesFrame>
      <Cover
        show={allCompleted}
        onClose={onClose}
        goNext={gotoStep4}
        customer={customer}
        ws={ws}
        am={am}
        duplicationMode={duplicationMode}
        originalWSId={originalWSId}
        yearRevisionVersion={yearRevisionVersion}
      />
    </WSFrame>
  );
};

const TextAreaFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  border: 1px solid #1fd7ff;
  flex-grow: 1;
  &.inactive {
    background: transparent;
    border: 1px solid #dddcdb;
  }
  &.empty {
    background-color: #eefaff;
  }
  &.attention {
    border: 1px solid #fc8661;
  }
  &.invalid {
    background-color: #f2c5c2;
    border: 1px solid #e94a3f;
  }
  &.valid {
    background-color: transparent;
  }
  .completed & {
    background-color: transparent;
    border: none;
    border: 1px solid transparent;
    border-bottom: 1px solid #1fd7ff;
  }

  & textarea {
    ${NotosansjpMediumCloudBurst12px}
    font-size: 15px;
    width: 15em;
    resize: none;
    overflow: hidden;
    border: none;
    margin-left: 8px;
    outline: none;
    padding: 0;
    background: transparent;
    font-weight: 400;
  }
  & textarea::placeholder {
    color: #9fa0a0;
    font-weight: 400;
  }
  & input {
    width: 250px;
    border: none;
    margin-left: 8px;
    font-size: 15px;
    outline: none;
    background: transparent;
  }
  & input::placeholder {
    color: #9fa0a0;
  }
`;

const PenIcon = styled.div`
  background-image: url(/img/penIconInactive.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 15px;
  height: 15px;
  margin: 0 15px;
  .active & {
    background-image: url(/img/penIconActive.svg);
  }
  .valid & {
    background-image: url(/img/valid_circle_check.svg);
  }
  .invalid & {
    background-image: url(/img/x-circle.svg);
  }
  .attention & {
    background-image: url(/img/alert-triangle.svg);
  }
  .none &,
  .completed & {
    background: none;
  }
`;

const ListTitle = styled.div`
  ${NotosansjpMediumCloudBurst12px}
  padding: 6px 12px;
  border-bottom: 1px solid #dddcdb;
`;

const TextArea = forwardRef((props, ref) => {
  const {
    value = "",
    onChange,
    placeholder,
    active = true,
    forceCanceled,
    valid,
    notFocusedTextAreaAfterAttention,
    onFocus,
    changedCustomer,
    numLines,
  } = props;
  const [focused, setFocused] = useState(false);

  const className = active
    ? valid
      ? (forceCanceled && notFocusedTextAreaAfterAttention) || changedCustomer
        ? "attention"
        : focused
        ? "active"
        : "valid"
      : value === ""
      ? "empty"
      : "invalid"
    : "inactive";

  const focusHandler = (e) => {
    onFocus(e);
    setFocused(true);
  };
  const blurHandler = (e) => {
    setFocused(false);
  };
  const completed = !active && value !== "";
  const frameStyle =
    numLines === 1 && completed ? { height: 23, marginBottom: 24 } : {};
  const areaStyle =
    numLines === 1 && completed ? { height: 23, marginBottom: 8 } : {};
  return (
    <TextAreaFrame className={className} style={frameStyle}>
      <textarea
        ref={ref}
        value={value || ""}
        placeholder={placeholder}
        onChange={onChange}
        disabled={!active}
        onFocus={focusHandler}
        onBlur={blurHandler}
        style={areaStyle}
      />
      <PenIcon />
    </TextAreaFrame>
  );
});

const TextField = (props) => {
  const {
    value = "",
    onChange,
    placeholder,
    active = true,
    forceCanceled,
    valid,
    notFocusedTextFieldAfterAttention,
    onFocus,
    changedCustomer,
  } = props;

  const [focused, setFocused] = useState(false);
  const className = active
    ? valid
      ? (forceCanceled && notFocusedTextFieldAfterAttention) || changedCustomer
        ? "attention"
        : focused
        ? "active"
        : "valid"
      : value === ""
      ? "empty"
      : "invalid"
    : "inactive";
  const focusHandler = (e) => {
    onFocus(e);
    setFocused(true);
  };
  const blurHandler = (e) => {
    setFocused(false);
  };

  return (
    <TextAreaFrame className={className}>
      <input
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={!active}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />
      <PenIcon />
    </TextAreaFrame>
  );
};

const Cover = (props) => {
  const {
    show = false,
    onClose,
    goNext,
    customer,
    ws,
    am,
    duplicationMode,
    originalWSId,
    yearRevisionVersion,
  } = props;
  const className = show ? "show" : "hide";
  const [submitted, setSubmitted] = useState(false);
  const [wsId, setWsId] = useState(null);
  const submitClassName = submitted ? "show" : "hide";
  const submitClickHandler = async (e) => {
    e.preventDefault();
    if (duplicationMode) {
      duplicateWorkspace({
        originalWorkspaceId: originalWSId,
        newWorkspaceName: ws,
        factorYear: yearRevisionVersion.year,
        factorRevision: yearRevisionVersion.revision,
      })
        .then((newWorkspaceId) => {
          setWsId(newWorkspaceId);
          setSubmitted(true);
        })
        .catch((err) => {
          console.log("WSProperties duplicateWorkspace error", err);
          alert(
            "WSの複製に失敗しました。指定された中期金融変数年度・版" +
              `（${yearRevisionVersion.year}年` +
              `${yearRevisionVersion.revision}版）` +
              "に指定された複製元WSが参照している政策AM内のベンチマーク" +
              "もしくは複製元で参照しているプロダクトが存在しないようです。" +
              "別の中期金融変数年度・版か、別のWSを元に複製してください。"
          );
        });
    } else {
      createWorkspace({
        customerId: customer.customerId,
        workspaceName: ws,
        pamId: am.id,
        factorYear: yearRevisionVersion.year,
        factorRevision: yearRevisionVersion.revision,
      })
        .then((res) => {
          setWsId(res);
          setSubmitted(true);
        })
        .catch((err) => {
          console.log("WSProperties createWorkspace error", err);
          alert(
            "WSの作成に失敗しました。指定された中期金融変数年度・版" +
              `（${yearRevisionVersion.year}年` +
              `${yearRevisionVersion.revision}版）` +
              "に指定された政策AM内のベンチマークが存在しないようです。" +
              "別の中期金融変数年度・版か、別の政策AMを指定してください。"
          );
        });
    }
  };
  const goNextHandler = (e) => {
    e.preventDefault();
    setSubmitted(false);
    goNext(wsId);
  };
  const cancelClickHandler = (e) => {
    e.preventDefault();
    setSubmitted(false);
    onClose();
  };
  return (
    <>
      <CoverFrame
        className={[className, submitted ? "submit" : ""].join(" ")}
      />
      <DialogFrame className={className}>
        <DialogHeader>
          <CheckIcon />
          <DialogTitle>WS基本設定 確定</DialogTitle>
        </DialogHeader>

        <DialogMessage>
          以下の設定でWS基本設定を完了します。 <br />
          <PropertiesConfirming>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>1 顧客</td>
                  <td>{customer?.customerName}</td>
                </tr>
                <tr>
                  <td>2 WS名称</td>
                  <td>{ws}</td>
                </tr>
                <tr>
                  <td>&nbsp;&nbsp;中期金融変数年度</td>
                  <td>
                    &nbsp;&nbsp;{yearRevisionVersion.year}年度{" "}
                    {yearRevisionVersion.revision}版
                  </td>
                </tr>
                <tr>
                  <td>3 政策AM</td>
                  <td>{am && am.name}</td>
                </tr>
              </tbody>
            </table>
          </PropertiesConfirming>
          この操作直後から編集不可になりますがよろしいですか？
        </DialogMessage>
        <DialogButtons>
          <DialogButton onClick={cancelClickHandler}>キャンセル</DialogButton>
          <DialogButton onClick={submitClickHandler} className="accent">
            決定
          </DialogButton>
        </DialogButtons>
      </DialogFrame>
      <DialogFrame className={submitClassName}>
        <DialogHeader style={{ justifyContent: "center" }}>
          <CheckIconGradation />
        </DialogHeader>

        <DialogMessage>
          <div style={{ height: 20 }}></div>
          <div>4 ポートフォリオ設定</div>
          <div>5 レポート作成画面に遷移します</div>
          <div style={{ height: 20 }}></div>
          <div>編集画面に遷移します</div>
        </DialogMessage>
        <DialogButtons>
          <DialogButton onClick={goNextHandler} className="accent">
            決定
          </DialogButton>
        </DialogButtons>
      </DialogFrame>
    </>
  );
};

const PropertiesConfirming = styled.div`
  padding: 20px 0;
`;

const CoverFrame = styled.div`
  background: linear-gradient(150deg, #50abff 0%, #affafe 100%);
  opacity: 0.4;
  z-index: 10000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &.hide {
    display: none;
  }
  &.submit {
    opacity: 1;
    animation: fadeout 2s linear;
  }
  @keyframes fadeout {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;
const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
`;
const DialogTitle = styled.div`
  ${NotosansjpMediumCloudBurst20px}
  margin-left: 16px;
  /* color: #192e55; */
`;

const DialogMessage = styled.div`
  ${NotosansjpMediumCloudBurst12px}
  color: #192E55;
  margin-top: 8px;
  margin-left: 40px;
`;

const DialogFrame = styled.div`
  opacity: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 24px;
  position: fixed;
  top: calc((100vh - 300px) / 2);
  left: calc((100vw - 448px) / 2);
  margin: 0 auto;
  width: 448px;
  height: 300px;
  z-index: 20000;
  &.hide {
    display: none;
  }
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
`;

const DialogButtons = styled.div`
  margin: 8px 24px 0;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  flex-grow: 1;
`;
const DialogButton = styled.button`
  ${NotosansjpMediumCloudBurst12px}
  color: #2397CE;
  width: 82px;
  padding: 8px 10px;
  height: 36px;
  margin-left: 20px;
  border: 1px solid #dddcdb;
  cursor: pointer;

  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }

  &.accent {
    border: unset;
    background-color: #2397ce;
    color: white;
  }
  &.accent:hover {
    background-color: #2b7eb0;
  }
  &.accent:active {
    background-color: #192e55;
  }
`;

const CheckIcon = styled.div`
  width: 24px;
  height: 24px;
  background-image: url(/img/CheckCircle.svg);
`;

const CheckIconGradation = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(/img/CheckCircleWGradation.svg);
`;

const AMSelector = (props) => {
  const {
    initAmId,
    ams,
    setAm,
    assetNaming,
    completed,
    setCompleted,
    status,
    forceCanceled,
    assetTypes,
    duplicationMode,
  } = props;

  const [selectedAm, setSelectedAm] = useState(null);
  useEffect(() => {
    if (!initAmId || !ams || ams.length <= 0) return;
    const initAm = ams.find((am) => am.id === initAmId);
    setSelectedAm(initAm);
  }, [initAmId, ams]);
  const [changedCustomer, setChangedCustomer] = useState(false);

  const [notReselectedAfterAttention, setNotReselectedAfterAttention] =
    useState(true);

  const generateSelectItemHandler = (item) => {
    return (e) => {
      e.preventDefault();
      if (completed) return;
      setNotReselectedAfterAttention(false);
      setChangedCustomer(false);
      if (item.id === selectedAm?.id) {
        setSelectedAm(null);
      } else {
        setSelectedAm(item);
      }
    };
  };

  useEffect(() => {
    if (!forceCanceled) return;
    setCompleted(false);
  }, [forceCanceled]);

  useEffect(() => {
    if (completed) setChangedCustomer(true);
    if (!initAmId) {
      setCompleted(false);
      setSelectedAm(sort(ams, "start-date-descend")[0]);
    }
  }, [ams]);

  const setAmHandler = (e) => {
    e.preventDefault();
    if (!completed) {
      setAm(selectedAm);

      setCompleted(true);
    }
  };
  const unsetAmHandler = (e) => {
    e.preventDefault();
    if (completed) {
      setCompleted(false);
    }
  };

  const checkBoxes = [
    { label: "名前昇順", value: "alphabetical-ascend" },
    { label: "名前降順", value: "alphabetical-descend" },
    { label: "適用開始日昇順", value: "start-date-ascend" },
    { label: "適用開始日降順", value: "start-date-descend" },
  ];

  const sort = (list, sortOption) => {
    switch (sortOption || sortBy) {
      case "alphabetical-ascend":
        return list.sort((a, b) => a.name?.localeCompare(b.name) || 0);
      case "alphabetical-descend":
        return list.sort((a, b) => b.name?.localeCompare(a.name) || 0);
      case "start-date-ascend":
        return list.sort((a, b) => a.baseDate?.localeCompare(b.baseDate) || 0);
      case "start-date-descend":
        return list.sort((a, b) => b.baseDate?.localeCompare(a.baseDate) || 0);
      default:
        return list;
    }
  };
  const [sortBy, setSortBy] = useState("start-date-descend");
  const generateRadioButtonChangeHandler = (value) => {
    return (e) => {
      e.preventDefault();
      setSortBy(value);
    };
  };
  const [opened, setOpened] = useState(false);
  const openCloseButtonClickHandler = (e) => {
    e.preventDefault();
    setOpened(!opened);
  };

  const active = status === "active";

  const selectedAmClassName = active
    ? (forceCanceled && notReselectedAfterAttention) || changedCustomer
      ? "attention"
      : "none"
    : "inactive";

  const sortedAssetAssigns =
    selectedAm && assetTypes
      ? assetTypes
          .filter((assetType) => selectedAm.assetAssigns.has(assetType))
          .map((assetType) => [
            assetType,
            selectedAm.assetAssigns.get(assetType),
          ])
      : [];

  return (
    <AMSelectionFrame className={status}>
      <PartLabel>政策AM選択</PartLabel>
      <SelectedItemFrame className={selectedAmClassName}>
        <SelectedItem className={selectedAm ? "selected" : "not-selected"}>
          {selectedAm ? selectedAm.name : "未選択"}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 14,
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                justifyContent: "right",
                alignItems: "center",
              }}
            >
              {selectedAm &&
                sortedAssetAssigns.map(([asset, assign]) => (
                  <AssetAssignSquare
                    key={asset}
                    style={{
                      borderColor: assetColors[asset],
                      borderWidth: "2.5px",
                      width: 20,
                      height: 20,
                      fontSize: 11,
                    }}
                  >
                    {parseInt(assign)}
                  </AssetAssignSquare>
                ))}
            </div>
            <PenIcon style={{ margin: 0 }} />
            <OpenCloseIcon
              style={{ width: 21, height: 21 }}
              className={opened ? "open" : "close"}
              onClick={openCloseButtonClickHandler}
            />
          </div>
        </SelectedItem>
        <div style={{ display: opened ? "block" : "none" }}>
          {selectedAm ? (
            <AMItemDetail
              am={selectedAm}
              assetNaming={assetNaming}
              assetTypes={assetTypes}
            />
          ) : (
            <></>
          )}
        </div>

        <ButtonsFrameSpacer style={{ flexBasis: 10 }} />
        <PartLabel>基準日</PartLabel>
        <DateLabel style={{ fontSize: 14 }}>
          {selectedAm ? selectedAm.baseDate : "未選択"}
        </DateLabel>

        <ButtonsFrame>
          <ButtonsFrameSpacer style={{ flexBasis: 17 }} />
          <Buttons>
            <ReEditButton
              className={completed && !duplicationMode ? "" : "inactive"}
              onClick={unsetAmHandler}
              disabled={duplicationMode}
            >
              変更
            </ReEditButton>
            <SaveButton
              className={selectedAm && !completed ? "" : "inactive"}
              onClick={setAmHandler}
            >
              確定
            </SaveButton>
          </Buttons>
        </ButtonsFrame>
        <ButtonsFrameSpacer style={{ flexBasis: 70 }} />
      </SelectedItemFrame>
      <div className="spacer" style={{ height: "16px" }} />
      <ListTitle>既存政策AM名称リスト</ListTitle>
      <SearchOptionButtons>
        {checkBoxes.map((checkBox, i) => (
          <div key={checkBox.value}>
            <RadioButton
              name="sort-order"
              id={checkBox.value}
              value={checkBox.value}
              type="radio"
              checked={sortBy === checkBox.value}
              onChange={generateRadioButtonChangeHandler(checkBox.value)}
            />
            <RadioButtonLabel
              htmlFor={checkBox.value}
              key={checkBox.value}
              className={checkBox.value === sortBy ? "checked" : ""}
              onClick={generateRadioButtonChangeHandler(checkBox.value)}
            >
              {checkBox.label}
            </RadioButtonLabel>
          </div>
        ))}
      </SearchOptionButtons>
      <div className="spacer" style={{ height: "20px" }} />
      <ItemList
        className="master-items"
        style={{ display: "flex", flexDirection: "column", gap: 8 }}
      >
        {sort(ams).map((am, i) => (
          <AMItem
            key={`master-product-${i}`}
            selected={selectedAm?.id === am.id}
            onClick={generateSelectItemHandler(am)}
            am={am}
            assetNaming={assetNaming}
            assetTypes={assetTypes}
          />
        ))}
      </ItemList>
      <PartCover
        style={{ display: status === "disabled" ? "block" : "none" }}
      />
    </AMSelectionFrame>
  );
};

const PartCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(243, 248, 250, 0.7);
`;

const Tester = styled.div`
  ${NotosansjpMediumCloudBurst12px}
  font-size: 15px;
  width: 15em;
  overflow: hidden;
  border: none;
  margin-left: 8px;
  outline: none;
  padding: 0;
  visibility: hidden;
  position: absolute;
`;

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const dateOfMonth = date.getDate().toString().padStart(2, "0");
  return `${year}/${month}/${dateOfMonth}`;
};

const WSNameSetter = (props) => {
  const {
    setWs,
    wsNames,
    completed,
    setCompleted,
    customer,
    status,
    forceCanceled,
    factorYearRevisionVersions,
    yearRevisionVersion,
    setYearRevisionVersion,
    initName = "",
  } = props;
  const [wsNameSetting, setWsNameSetting] = useState(initName);
  useEffect(() => {
    if (!initName || initName.length <= 0) return;
    setWsNameSetting(initName);
  }, [initName]);
  // const [date, setDate] = useState(formatDate(new Date()));
  const [valid, setValid] = useState(false);
  const [validDate, setValidDate] = useState(false);

  const testerRef = useRef(null);
  const textAreaRef = useRef(null);

  const [
    notFocusedTextAreaAfterAttention,
    setNotFocusedTextAreaAfterAttention,
  ] = useState(true);

  const [changedCustomerTA, setChangedCustomerTA] = useState(false);

  const state = useRef({ cursorPos: null });

  const isValid = (value) =>
    !(
      !value ||
      value.length <= 0 ||
      wsNames
        .map((s) => s.replace(/\s/, ""))
        .indexOf(value.replace(/\s/, "")) >= 0
    );

  useEffect(() => {
    if (!textAreaRef.current || !testerRef.current) {
      return;
    }
    const value = textAreaRef.current.value;
    const newValid = isValid(value);
    setValid(newValid);
    if (!newValid) {
      setCompleted(false);
      if (completed) {
        setChangedCustomerTA(true);
        // setChangedCustomerTF(true);
      }
    }
  }, [wsNames.join("")]);

  useEffect(() => {
    if (!forceCanceled) return;
    setCompleted(false);
    setNotFocusedTextAreaAfterAttention(true);
  }, [forceCanceled]);

  const [numLines, setNumLines] = useState(1);

  const adjustText = () => {
    if (!textAreaRef.current || !testerRef.current) {
      return;
    }
    const value = textAreaRef.current.value;
    const lines = value.split("\n");
    const newLines = lines.length > 2 ? lines.slice(0, 2) : lines;
    const newValue = newLines.join("\n");
    testerRef.current.innerText = newValue;
    const height = testerRef.current.clientHeight;
    const newNumLines = height > 30 ? 2 : 1;
    setNumLines(newNumLines);

    const tooTall = height >= 68;
    const refinedText = tooTall
      ? newValue.substring(0, newValue.length - 1)
      : newValue;
    const newCursorPos = textAreaRef.current.selectionStart;

    if (tooTall) {
      state.cursorPos = newCursorPos;
      setTimeout(adjustText, 10);
    } else {
      if (state.cursorPos !== null && state.cursorPos !== undefined) {
        textAreaRef.current.selectionStart = state.cursorPos - 1;
        textAreaRef.current.selectionEnd = state.cursorPos - 1;
        state.cursorPos = null;
      }
      setWsNameSetting(refinedText);
      const newValid = isValid(newValue);
      setValid(newValid);
    }
  };

  const changeWsNameHandler = (e) => {
    adjustText();
  };

  const setWsNameHandler = (e) => {
    e.preventDefault();
    if (completed) return;
    if (!wsNameSetting || wsNameSetting.length <= 0) return;
    if (wsNames.indexOf(wsNameSetting) >= 0) return;
    setWs(wsNameSetting);
    setCompleted(true);
  };

  const unsetWsNameHandler = (e) => {
    e.preventDefault();
    if (completed) {
      setCompleted(false);
    }
  };

  const checkBoxes = [
    { label: "名前昇順", value: "alphabetical-ascend" },
    { label: "名前降順", value: "alphabetical-descend" },
    { label: "更新日昇順", value: "updated-ascend" },
    { label: "更新日降順", value: "updated-descend" },
  ];
  const [sortBy, setSortBy] = useState("alphabetical-ascend");
  const generateRadioButtonChangeHandler = (value) => {
    return (e) => {
      e.preventDefault();
      setSortBy(value);
    };
  };

  const onFocusTextArea = (e) => {
    e.preventDefault();
    setNotFocusedTextAreaAfterAttention(false);
    setChangedCustomerTA(false);
  };

  return (
    <WSNameSetterFrame className={status}>
      <SelectedItemFrame>
        <div style={{ display: "flex", flexDirection: "column", gap: 22 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <PartLabel style={{ width: 100 }}>WS名称設定</PartLabel>
            <TextArea
              onChange={changeWsNameHandler}
              onFocus={onFocusTextArea}
              value={wsNameSetting}
              placeholder="未入力"
              ref={textAreaRef}
              active={status !== "disabled" && !completed}
              forceCanceled={forceCanceled}
              valid={valid}
              notFocusedTextAreaAfterAttention={
                notFocusedTextAreaAfterAttention
              }
              changedCustomer={changedCustomerTA}
              numLines={numLines}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <PartLabel style={{ width: 100 }}>基準日</PartLabel>
            <YearRevisionVersionSelector
              factorYearRevisionVersions={factorYearRevisionVersions}
              yearRevisionVersion={yearRevisionVersion}
              setYearRevisionVersion={setYearRevisionVersion}
            />
          </div>
          <div className="spacer" style={{ height: "12px" }} />
        </div>

        <Buttons>
          <ReEditButton
            className={completed ? "" : "inactive"}
            onClick={unsetWsNameHandler}
            disabled={!completed}
          >
            変更
          </ReEditButton>
          <SaveButton
            className={completed || !valid ? "inactive" : ""}
            onClick={setWsNameHandler}
            disabled={!valid}
          >
            確定
          </SaveButton>
        </Buttons>
      </SelectedItemFrame>
      <Tester ref={testerRef} />
      <div className="spacer" style={{ height: "79px" }} />
      <ListTitle>既存WS名称リスト</ListTitle>
      <SearchOptionButtons>
        {checkBoxes.map((checkBox, i) => (
          <div key={checkBox.value}>
            <RadioButton
              name="sort-order"
              id={checkBox.value}
              value={checkBox.value}
              type="radio"
              checked={sortBy === checkBox.value}
              onChange={generateRadioButtonChangeHandler(checkBox.value)}
            />
            <RadioButtonLabel
              htmlFor={checkBox.value}
              key={checkBox.value}
              className={checkBox.value === sortBy ? "checked" : ""}
              onClick={generateRadioButtonChangeHandler(checkBox.value)}
            >
              {checkBox.label}
            </RadioButtonLabel>
          </div>
        ))}
      </SearchOptionButtons>
      <div className="spacer" style={{ height: "20px" }} />
      {customer && customer?.customerName?.length >= 0 && (
        <>
          <ItemList className="master-items">
            {wsNames.map((product, i) => (
              <Item key={`master-product-${i}`} className={["master", "flat"]}>
                <ItemLabel>{product}</ItemLabel>
              </Item>
            ))}
          </ItemList>
        </>
      )}
      <PartCover
        style={{ display: status === "disabled" ? "block" : "none" }}
      />
    </WSNameSetterFrame>
  );
};

const CustomerSelector = (props) => {
  const {
    initCustomer,
    customers,
    setCustomer,
    completed,
    setCompleted,
    status,
    forceCanceled,
    duplicationMode,
  } = props;

  const [selectedCustomer, setSelectedCustomer] = useState(initCustomer);
  useEffect(() => {
    if (!initCustomer) return;
    setSelectedCustomer(initCustomer);
  }, [initCustomer]);

  const [keyword, setKeyword] = useState("");
  const [sortBy, setSortBy] = useState("alphabetical-ascend");
  const checkBoxes = [
    { label: "名前昇順", value: "alphabetical-ascend" },
    { label: "名前降順", value: "alphabetical-descend" },
    { label: "更新日昇順", value: "updated-ascend" },
    { label: "更新日降順", value: "updated-descend" },
  ];
  const generateSelectItemHandler = (item) => {
    return (e) => {
      e.preventDefault();
      if (completed) return;

      if (selectedCustomer && item.customerId === selectedCustomer.customerId) {
        setSelectedCustomer(null);
      } else {
        setSelectedCustomer(item);
      }
      if (forceCanceled) {
        setNotTouchedSelectorAfterAttention(false);
      }
    };
  };

  const generateRadioButtonChangeHandler = (value) => {
    return (e) => {
      e.preventDefault();
      setSortBy(value);
    };
  };

  const changeKeywordHandler = (e) => {
    e.preventDefault();
    setKeyword(e.target.value);
  };

  const setCustomerHandler = (e) => {
    e.preventDefault();
    if (!completed) {
      setCustomer(selectedCustomer);
      setCompleted(true);
    }
  };

  const unsetCustomerHandler = (e) => {
    e.preventDefault();
    if (completed) {
      setCompleted(false);
    }
  };

  const sort = (list) => {
    switch (sortBy) {
      case "alphabetical-ascend":
        return list.sort((a, b) =>
          a.customerName.localeCompare(b.customerName)
        );
      case "alphabetical-descend":
        return list.sort((a, b) =>
          b.customerName.localeCompare(a.customerName)
        );
      case "updated-ascend":
        return list.sort((a, b) =>
          a.customerName.localeCompare(b.customerName)
        );
      case "updated-descend":
        return list.sort((a, b) =>
          b.customerName.localeCompare(a.customerName)
        );
      default:
        return list;
    }
  };
  const [
    notTouchedSelectorAfterAttention,
    setNotTouchedSelectorAfterAttention,
  ] = useState(true);

  useEffect(() => {
    if (!forceCanceled) {
      setNotTouchedSelectorAfterAttention(true);
    }
  }, [forceCanceled]);

  const active = status === "active";
  const selectedCustomerClassName = active
    ? forceCanceled && notTouchedSelectorAfterAttention
      ? "attention"
      : "none"
    : "inactive";

  return (
    <CustomerSelectionFrame className={status}>
      <PartLabel>顧客選択</PartLabel>
      <SelectedItemFrame className={selectedCustomerClassName}>
        <SelectedItem
          className={selectedCustomer ? "selected" : "not-selected"}
        >
          {selectedCustomer ? selectedCustomer.customerName : "未選択"}
          <PenIcon />
        </SelectedItem>
        <ButtonsFrame>
          <ButtonsFrameSpacer />
          <Buttons>
            <ReEditButton
              className={completed && !duplicationMode ? "" : "inactive"}
              onClick={unsetCustomerHandler}
              disabled={!completed || duplicationMode}
            >
              変更
            </ReEditButton>
            <SaveButton
              className={selectedCustomer && !completed ? "" : "inactive"}
              onClick={setCustomerHandler}
            >
              確定
            </SaveButton>
          </Buttons>
        </ButtonsFrame>
        <ButtonsFrameSpacer style={{ flexBasis: 70 }} />
      </SelectedItemFrame>
      <SearchForm>
        <SearchFrame>
          <SearchField
            placeholder="Search keywords here"
            value={keyword}
            onChange={changeKeywordHandler}
            disabled={status === "completed"}
          />
          <SearchButton />
        </SearchFrame>
        <SearchOptionButtons>
          {checkBoxes.map((checkBox, i) => (
            <div key={checkBox.value}>
              <RadioButton
                name="sort-order"
                id={checkBox.value}
                value={checkBox.value}
                type="radio"
                checked={sortBy === checkBox.value}
                onChange={generateRadioButtonChangeHandler(checkBox.value)}
              />
              <RadioButtonLabel
                htmlFor={checkBox.value}
                key={checkBox.value}
                className={checkBox.value === sortBy ? "checked" : ""}
                onClick={generateRadioButtonChangeHandler(checkBox.value)}
              >
                {checkBox.label}
              </RadioButtonLabel>
            </div>
          ))}
        </SearchOptionButtons>
      </SearchForm>
      <ItemList className="master-items">
        {sort(customers).map((customer, i) =>
          keyword.length >= 0 &&
          customer.customerName.indexOf(keyword) < 0 ? null : (
            <Item
              key={`master-product-${i}`}
              className={[
                "master",
                selectedCustomer &&
                customer.customerId === selectedCustomer.customerId
                  ? "selected"
                  : "",
              ]}
              onClick={generateSelectItemHandler(customer)}
            >
              <ItemLabel>{customer.customerName}</ItemLabel>
              {/* <Icon className="master"></Icon> */}
            </Item>
          )
        )}
      </ItemList>
    </CustomerSelectionFrame>
  );
};

export default WSProperties;
export { AMItem };

const ButtonsFrame = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsFrameSpacer = styled.div`
  flex-basis: 74px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
`;

const SaveButton = styled.button`
  padding: 8px 0;
  margin-left: 10px;
  width: 100px;
  height: 36px;

  ${NotosansjpMediumWhite12px}
  background-color: #2397CE;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }
  &.inactive {
    background-color: #9fa0a0;
    cursor: initial;
  }
`;

const ReEditButton = styled.button`
  padding: 8px 0;
  margin-left: 10px;
  width: 100px;
  height: 36px;
  ${NotosansjpMediumWhite12px}
  color: #2397ce;

  background-color: transparent;
  border: 1px solid #dddcdb;
  &:not(.inactive):hover {
    background-color: #e5eced;
    cursor: pointer;
  }
  &:not(.inactive):active {
    background-color: #192e55;
  }
  &.inactive {
    color: #9fa0a0;
  }
`;

const WSPropertiesFrame = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  gap: 0 10px;
`;

const SelectedItemFrame = styled.div`
  ${NotosansjpNormalNavy13px}
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const SelectedItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${NotosansjpNormalNavy13px}
  font-size: 20px;
  border-bottom: 1px solid #b7eaff;
  padding-bottom: 11px;
  &.not-selected {
    color: #d2d2d2;
  }
  .attention & {
    border-color: #fc8661;
  }
`;

const ItemLabel = styled.div`
  ${ValignTextMiddle}
  ${NotosansjpNormalCloudBurst12px}

  letter-spacing: 0;
  padding-left: 5px;
  white-space: nowrap;
`;

const AssetAssignSquare = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid red;
  ${NotosansjpNormalCloudBurst12px}
  font-size: 10px;
  font-weight: 500;
  width: 22px;
  height: 22px;
  background-color: #ffffff;
`;

const AMItemDetail = (props) => {
  const { am, assetNaming, assetTypes } = props;
  const bmView = (bm) => {
    const numElements = Object.keys(bm).length;
    return bm === undefined
      ? ""
      : numElements === 1
      ? Object.keys(bm)[0]
      : `${Object.keys(bm)[0]} 他${numElements}件`;
  };
  const sortedAssetAssigns =
    am && assetTypes
      ? assetTypes
          .filter((assetType) => am.assetAssigns.has(assetType))
          .map((assetType) => [assetType, am.assetAssigns.get(assetType)])
      : [];

  return (
    <div
      style={{
        fontSize: 11,
        padding: "16px 20px",
        display: "flex",
        flexDirection: "column",
        gap: 24,
        backgroundColor: "#EEFAFF",
      }}
    >
      <table style={{ alignSelf: "start" }}>
        <thead>
          <tr>
            <th style={{ textAlign: "left" }}>適用開始日</th>
            <th style={{ textAlign: "left" }}>作成日</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: 100 }}>{am.baseDate}</td>
            <td style={{ width: 100 }}>{am.createDate}</td>
          </tr>
        </tbody>
      </table>
      <table style={{ fontSize: 10 }}>
        <tbody>
          {sortedAssetAssigns.map(([asset, assign]) => (
            <tr key={asset}>
              <td
                style={{
                  whiteSpace: "nowrap",
                  width: "40px",
                  padding: "6px 60px 0 5px",
                }}
              >
                {assetNaming && assetNaming.hasOwnProperty(asset)
                  ? assetNaming[asset]
                  : asset}
              </td>
              <td
                style={{
                  whiteSpace: "nowrap",
                  width: "20px",
                  padding: "6px 10px 0 0 ",
                  textAlign: "right",
                }}
              >
                {assign}%
              </td>
              <td
                style={{
                  minWidth: 110,
                  display: "flex",
                  alignItems: "flex-end",
                  height: 20,
                  whiteSpace: "nowrap",
                }}
              >
                <div
                  style={{
                    width: `${assign}%`,
                    height: 8,
                    backgroundColor: assetColors[asset],
                  }}
                />
              </td>
              <td style={{ width: 200, whiteSpace: "nowrap" }}>
                {bmView(am.assetBMs.get(asset))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AMItem = (props) => {
  const {
    am,
    assetNaming,
    selected,
    onClick,
    setPamToDelete,
    setPamToEdit,
    extensible = true,
    deletable = false,
    assetTypes,
  } = props;

  const [opened, setOpened] = useState(false);

  const openCloseButtonClickHandler = (e) => {
    e.preventDefault();
    setOpened(!opened);
  };
  const className = ["master", selected ? "selected" : ""];

  useEffect(() => {
    setOpened(false);
  }, [extensible]);

  const generateDeletePAM = (pam) => (e) => {
    console.log("click delete pam button", pam);
    e.preventDefault();
    setPamToDelete(pam);
  };
  const generateEditPAM = (pam) => (e) => {
    console.log("click edit pam button", pam);
    e.preventDefault();
    setPamToEdit(pam);
  };

  const sortedAssetAssigns =
    am && assetTypes
      ? assetTypes
          .filter((assetType) => am.assetAssigns.has(assetType))
          .map((assetType) => [assetType, am.assetAssigns.get(assetType)])
      : [];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <AMItemFrame className={className}>
        <AMItemDiv
          onClick={onClick}
          style={{ flexGrow: 1, margin: 0, border: "none" }}
        >
          <ItemLabel>{am.name}</ItemLabel>
          <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                justifyContent: "right",
                marginRight: 20,
                alignItems: "center",
                width: 22 * 8 + 5 * (8 - 1),
              }}
            >
              {sortedAssetAssigns.map(([asset, assign]) => (
                <AssetAssignSquare
                  key={asset}
                  style={{ borderColor: assetColors[asset] }}
                >
                  {parseInt(assign)}
                </AssetAssignSquare>
              ))}
            </div>
            <DateLabel>{am.baseDate}</DateLabel>
            {deletable && (
              <>
                <div
                  style={{
                    width: 24,
                    height: 29,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {am.isDeletable && (
                    <PenEditIcon
                      onClick={generateEditPAM(am)}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                </div>
                <div
                  style={{
                    width: 24,
                    height: 29,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {am.isDeletable && (
                    <TrashIcon
                      onClick={generateDeletePAM(am)}
                      style={{ width: 24, height: 24 }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </AMItemDiv>
        {extensible && (
          <OpenCloseIcon
            className={opened ? "open" : "close"}
            onClick={openCloseButtonClickHandler}
          />
        )}
      </AMItemFrame>
      <div style={{ display: opened && extensible ? "block" : "none" }}>
        <AMItemDetail
          am={am}
          assetNaming={assetNaming}
          assetTypes={assetTypes}
        />
      </div>
    </div>
  );
};

const AMItemFrame = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 0 3px 0 5px;
  border: 2px solid #e4f7ff;
  cursor: pointer;

  &:hover {
    background-color: #d2f2ff;
  }
  &:active,
  &.selected {
    background-color: #92e0ff;
  }
  .completed &,
  .completed &:hover,
  .completed &:active,
  .completed &.selected {
    background-color: transparent;
    border: 2px solid #dddcdb;
    cursor: initial;
  }
`;

const OpenCloseIcon = styled.div`
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 1;
  &.open {
    background-image: url(/img/chevron-up.svg);
  }
  &.close {
    background-image: url(/img/chevron-down.svg);
  }
  &:hover {
    background-color: #d2f2ff;
  }
`;

const DateLabel = styled.div`
  ${NotosansjpNormalNavy12px}
  white-space: nowrap;
`;

const AMItemDiv = styled.div`
  /* width: 300px; */
  height: 24px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 2px solid #e4f7ff;
`;

const Item = styled.div`
  /* width: 300px; */
  height: 28px;
  cursor: pointer;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border: 2px solid #e4f7ff;

  &:not(.flat):hover {
    background-color: #d2f2ff;
  }
  &:not(.flat):active,
  &:not(.flat).selected {
    background-color: #92e0ff;
  }
  &.flat {
    cursor: initial;
    border: none;
  }
  .completed &:not(.flat),
  .completed &:not(.flat):hover,
  .completed &:not(.flat):active,
  .completed &:not(.flat).selected {
    background-color: transparent;
    border: 2px solid #dddcdb;
    cursor: initial;
  }
`;
const ItemList = styled.div`
  height: calc(100vh - 380px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    margin-right: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5eced;
    border-radius: 4px;
    margin-right: 6px;
  }

  /* &.master-items {
    width: 320px;
  }
  &.selected-items {
    width: 340px;
  } */
`;
const SearchForm = styled.div`
  /* width: 300px; */
  height: 100px;
  display: flex;
  flex-direction: column;
`;

const SearchFrame = styled.div`
  margin-top: 16px;
  /* width: 300px; */
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #e5eced;
`;
const SearchField = styled.input`
  ${NotosansjpNormalNavy12px}
  /* width: 248px; */
  height: 18px;
  margin-left: 12px;
  border: none;
  outline: unset;
  background-color: transparent;
  &::placeholder {
    color: #9fa0a0;
  }
`;
const SearchButton = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(/img/magnifier.svg);
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  .inactive & {
    cursor: initial;
  }

  margin-right: 9px;
  border: none;
`;

const SearchOptionButtons = styled.div`
  margin-top: 7px;
  width: 300px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RadioButton = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  cursor: pointer;
  .inactive & {
    cursor: initial;
  }
`;

const RadioButtonLabel = styled.label`
  ${NotosansjpNormalNavy10px}
  display: flex;
  align-items: center;
  color: #192e55;
  border-radius: 10px;
  border: 1px solid #dddcdb;
  /* width: 70px; */
  padding: 0 7px;
  height: 20px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  &.checked {
    background: #d2f2ff;
    border: 1px solid #92e0ff;
  }
  .completed &.checked {
    background: #dddcdb;
    border: 1px solid #9fa0a0;
  }
  .completed & {
    cursor: initial;
  }
`;

const Icon = styled.div`
  ${NotosansjpNormalCloudBurst10px}
  height:20px;
  width: 20px;
  border: unset;
  outline: unset;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 7px;
  .master & {
    background-image: url(/img/PlusBlue.svg);
  }
  .master:hover & {
    background-image: url(/img/PlusLBL.svg);
  }
  .master:active & {
    background-image: url(/img/PlusLBL.svg);
  }
  .item & {
    background-image: url(/img/check.svg);
  }
  .item:hover & {
    background-image: url(/img/minus.svg);
  }
  .item:active & {
    background-image: url(/img/minus.svg);
  }
`;

const PartLabel = styled.div`
  ${NotosansjpNormalNavy13px}
  font-size: 11px;
`;

const CustomerSelectionFrame = styled.div`
  flex-basis: 550px;
  flex-grow: 550;
  /* border-right: 1px solid #e6eff3; */
  padding: 60px 75px;
  background-color: #ffffff;
  &.completed {
    background-color: transparent;
  }
`;

const WSNameSetterFrame = styled.div`
  flex-basis: 550px;
  flex-grow: 550;
  /* border-right: 1px solid #e6eff3; */
  padding: 60px 75px;
  background-color: #ffffff;
  position: relative;
  &.completed {
    background-color: transparent;
  }
`;

const AMSelectionFrame = styled.div`
  flex-basis: 650px;
  flex-grow: 650;
  padding: 60px 75px;
  background-color: #ffffff;
  position: relative;
  &.completed {
    background-color: transparent;
  }
`;
