import { Tooltip } from "@mui/material";
import { BigNumber } from "bignumber.js";
import { PdfButton } from "components/PageEditButtons";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { PrinterIcon } from "utils/SVGIcons";
import { h2z } from "utils/StringUtils";

import PageEditButtons from "components/PageEditButtons";
import PageViewStyleSwitcher from "components/PageViewStyleSwitcher";

import ChartSelectionMenuItem from "components/ChartSelectionMenuItem";
import ContextMenu from "components/ContextMenu";
import LayoutPattern from "components/LayoutPattern";
import PageTemplate from "components/PageTemplate";
import PageTileViewer from "components/PageTileViewer";
import Thumbnail from "components/Thumbnail";
import WSFrame from "components/WSFrame";

import { getAssetNamingByCustomerId, getBusyoByCustomerId } from "api/Customer";
import {
  getBenchmarksIdMapByCustomerId,
  getProductsForWSByWSId,
} from "api/ProductMaster";
import { getAnalysisResult, getWorkspace, updateReport } from "api/Workspace";
import {
  createDefaultReport,
  mergePurpose,
  sortAssetsByAssetTypeStandardOrder,
} from "utils/PortfolioUtils";

import { templates } from "components/PageRenderers";

import { generateChartDefinitions } from "components/PageElements";
import styled from "styled-components";
import { NotosansjpMediumWhite12px } from "../../styledMixins";
import "./PageTemplate.css";

// when developing createDefaultReport function, set this to true.
// Then you can see the result of createDefaultReport function when you reload the page.
const ANYTIME_AUTO_CREATION_MODE = false;

/** グラフ選択用コンポーネント */
const ChartSelectMenu = (props) => {
  const { selectedChart, changeHandler, chartDefinitions } = props;

  const chartTypeList = Object.keys(chartDefinitions.chartTypeComponentMap);
  const clickHandler = (e) => {
    e.preventDefault();
    const newChartType = e.target.value;
    changeHandler(newChartType);
  };
  const active = !!selectedChart;

  return (
    <div className="chart_select_menu">
      {chartTypeList.map((chartType) => (
        <div key={chartType}>
          <ChartSelectionMenuItem
            chartType={chartType}
            title={chartDefinitions.chartTypeDisplayTitleMap[chartType]}
            onClick={clickHandler}
            active={active}
            selected={chartType === selectedChart}
            disabled={!active || chartType === selectedChart}
          />
        </div>
      ))}
    </div>
  );
};

const props2String = (props) => {
  return Object.keys(props)
    .map((key) => {
      return `${key}: ${JSON.stringify(props[key])}`;
    })
    .join("\n");
};

/** ページプロパティ表示用コンポーネント */
const PageProperty = (props) => {
  return (
    <div className="page_property">
      <div>ページプロパティ</div>
      {Object.keys(props).map((key) => {
        return (
          <div key={key} className="property">
            <div className="property-key">{key}</div>
            <div className="property-value">
              <pre>{props2String(props[key])}</pre>
            </div>
          </div>
        );
      })}
    </div>
  );
};

/** レイアウト選択用コンポーネント */
const LayoutSelector = (props) => {
  const { selectedLayout, changeHandler } = props;
  const active = !!selectedLayout;
  const clickHandler = (e) => {
    e.preventDefault();
    const newLayout = e.target.value;
    changeHandler(newLayout);
  };
  return (
    <div className="chart_select_menu">
      {/* <div className={`chart_select_header ${active ? "active" : "inactive"}`}>
        レイアウト一覧
      </div> */}
      {Object.keys(templates).map((layout) => (
        <div key={layout}>
          <LayoutPattern
            patternName={layout}
            onClick={clickHandler}
            disabled={!active || layout === selectedLayout}
          />
        </div>
      ))}
    </div>
  );
};

const common_height = "calc(100vh - 115px)";
const body_common_height = "calc(100vh - 115px - 45px)";

/** ページ編集用コンポーネント */
const PageEditor = (props) => {
  const {
    params,
    changeHandler,
    renderer,
    selectedLayout,
    onLayoutChange,
    chartDefinitions,
    pageIndex,
  } = props;

  const {
    chart1Props = {},
    chart2Props = {},
    chart3Props = {},
    chart4Props = {},
  } = params;
  const chartProps = {
    chart1: chart1Props,
    chart2: chart2Props,
    chart3: chart3Props,
    chart4: chart4Props,
  };
  const chartTypes = Object.entries(chartProps).reduce((acc, [key, value]) => {
    acc[key] = value?.chartType;
    return acc;
  }, {});

  const [selectedChartId, setSelectedChartId] = useState(null);

  const onChartChange = (newChartType) => {
    if (selectedChartId === null) {
      return;
    }
    const chartPropsKey = `${selectedChartId}Props`;
    const newParams = Object.assign(Object.assign(Object.assign({}, params)), {
      [chartPropsKey]: { chartType: newChartType },
    });
    changeHandler(newParams);
  };

  const [hideDisplay, setHideDisplay] = useState(false);
  const state = useRef({
    hide: false,
  }).current;

  const onTabClick = (e) => {
    const classNames = e.target.parentNode.className.split(" ");
    const shouldBeSwitched =
      classNames.indexOf("react-tabs__tab--selected") >= 0 || hideDisplay;
    if (shouldBeSwitched) {
      const shouldHide = !hideDisplay;
      setHideDisplay(shouldHide);
      state.hide = shouldHide;
      if (shouldHide) {
        const newClassNames = [...classNames];
        const index = newClassNames.indexOf("react-tabs__tab--selected");
        newClassNames.splice(index, 1, "hidden");
        const newClassName = newClassNames.join(" ");
        e.target.parentNode.className = newClassName;
      } else {
        const newClassNames = [...classNames];
        const index = newClassNames.indexOf("hidden");
        newClassNames.splice(index, 1, "react-tabs__tab--selected");
        const newClassName = newClassNames.join(" ");
        e.target.parentNode.className = newClassName;
      }
    }
  };

  const ref = useRef(null);
  const [scale, setScale] = useState(1);

  const [additionalMargin, setAdditionalMargin] = useState([0, 0]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const frameW = entries[0].contentRect.width;
      const frameH = entries[0].contentRect.height;
      const w = frameW - 160;
      const h = frameH - 35;
      const idealWidth = 297;
      const idealHeight = 210;

      const wIsBase = w / h <= idealWidth / idealHeight;
      const minWidth = 1093;
      const minHeight = 903;

      const ratio = wIsBase ? w / minWidth : h / minHeight;

      const sectionH = idealHeight * ratio * 3.7795275591;
      const sectionW = idealWidth * ratio * 3.7795275591;

      const topMargin = (frameH - sectionH) / 2 - 30;
      const leftMargin = (frameW - sectionW) / 2 - 80;
      setAdditionalMargin([topMargin, leftMargin]);

      setScale(ratio);
      const style = {
        transform: `scale(${ratio}, ${ratio})`,
        transformOrigin: "top left",
      };
      const section = ref.current?.querySelector("section");
      if (section) {
        section.style.transform = style.transform;
        section.style.transformOrigin = style.transformOrigin;
      }
    });
    resizeObserver.observe(ref.current);
  }, [ref.current]);

  useEffect(() => {
    const style = {
      transform: `scale(${scale}, ${scale})`,
      transformOrigin: "top left",
    };
    const section = ref.current?.querySelector("section");
    if (section) {
      section.style.transform = style.transform;
      section.style.transformOrigin = style.transformOrigin;
    }
    // }, [parametors]);
  }, [params]);
  const [tabIndex, setTabIndex] = useState(0);
  const page = (
    <PageTemplate
      {...props}
      setTabIndex={setTabIndex}
      selectedChartId={selectedChartId}
      setSelectedChartId={setSelectedChartId}
      editable={true}
      pageIndex={pageIndex}
    />
  );

  const { viewMode } = useContext(PageEditorContext);

  return (
    <EditorPageFrame>
      <EditMenuFrame className="edit-menu-frame">
        <Tabs
          className="graph-tab"
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexShrink: 0,
            }}
          >
            <TabHeaderSpacer />
            <div
              className={hideDisplay ? "hidden" : ""}
              style={{
                width: "310px",
                flexBasis: "310px",
                flexShrink: 0,
                display: viewMode ? "none" : "block",
              }}
            >
              <TabList>
                <Tab>
                  <div onClick={onTabClick}>
                    <img src="/img/Article.svg" />
                    レイアウトパターン
                  </div>
                </Tab>
                <Tab>
                  <div onClick={onTabClick}>
                    <img src="/img/ChartLineUp.svg" />
                    グラフ選択
                  </div>
                </Tab>
              </TabList>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexGrow: 1,
              position: "relative",
            }}
          >
            <div
              className="section-holder-frame"
              style={{ flexGrow: 1, minWidth: 1093 }}
              ref={ref}
            >
              <SectionHolder
                style={{
                  marginTop: additionalMargin[0],
                  marginLeft: additionalMargin[1],
                }}
              >
                <div style={{ padding: "0 80px" }}>
                  {typeof renderer === "function" ? page : <></>}
                </div>
              </SectionHolder>
            </div>

            <div
              className={`edit-menu-dummy ${hideDisplay ? "hidden" : "shown"}`}
            >
              <div
                className={`edit-menu ${hideDisplay ? "hidden" : "shown"}`}
                style={viewMode ? { width: 0 } : {}}
              >
                <TabPanel>
                  <LayoutSelector
                    selectedLayout={selectedLayout}
                    changeHandler={onLayoutChange}
                  />
                </TabPanel>
                <TabPanel>
                  <ChartSelectMenu
                    selectedChart={chartTypes[selectedChartId]}
                    changeHandler={onChartChange}
                    chartDefinitions={chartDefinitions}
                  />
                </TabPanel>
              </div>
            </div>
          </div>
        </Tabs>
      </EditMenuFrame>
    </EditorPageFrame>
  );
};

const SectionHolder = styled.div`
  & section {
    transform-origin: top left;
  }
`;

const TabHeaderSpacer = styled.div`
  flex-grow: 1;
  background-color: #e5edf1;
  min-width: 1093px;
`;

const EditorPageFrame = styled.div`
  display: flex;
  height: 100%;
`;

const EditMenuFrame = styled.div`
  width: 100%;
  margin: 0;
  background-color: #e5edf1;

  & .react-tabs {
    display: flex;
    flex-direction: column;
  }

  & .react-tabs__tab-list {
    border-bottom: unset;
  }

  & .react-tabs__tab {
    display: block;
    padding: 5px;
    height: 35px;
    text-align: center;
    color: #2397ce;
    flex-grow: 1;
    min-width: 150px;
    font-size: 10pt;
    bottom: -1px;
    position: relative;
    background-color: #e5edf1;
  }

  & .react-tabs__tab--selected {
    display: block;
    padding: 5px;
    height: 35px;
    text-align: center;
    flex-grow: 1;
    min-width: 150px;
    font-size: 10pt;
    bottom: -1px;
    position: relative;

    color: #2397ce;
    border: unset;
    background-color: #ffffff;
  }

  & .react-tabs__tab--selected img {
    vertical-align: middle;
  }

  & .hidden .react-tabs__tab {
    background-color: transparent;
  }

  & .edit-menu-dummy {
    flex-basis: 310px;
    flex-shrink: 0;
    position: relative;
    height: ${body_common_height};
  }

  & .edit-menu-dummy.shown {
    animation: showing 0.1s ease-out;
    animation-fill-mode: forwards;
    flex-basis: 0;
    opacity: 0;
  }

  & .edit-menu-dummy.hidden {
    animation: hiding 0.2s ease-out;
    animation-fill-mode: forwards;
    flex-basis: 0;
    opacity: 0;
  }

  & .edit-menu {
    width: 310px;
    position: absolute;
    top: 0;
    right: 0;
    height: ${body_common_height};
  }
  & .edit-menu.shown {
    animation: showing2 0.1s ease-out;
    animation-fill-mode: forwards;
    opacity: 0;
    right: -310px;
    /* display: none; */
  }
  & .edit-menu.hidden {
    animation: hiding2 0.2s ease-out;
    animation-fill-mode: forwards;
    opacity: 0;
    right: -310px;
    /* display: none; */
  }

  @keyframes hiding {
    0% {
      flex-basis: 310px;
      opacity: 1;
    }
    100% {
      flex-basis: 0;
      opacity: 0;
    }
  }
  @keyframes hiding2 {
    0% {
      right: 0;
      opacity: 1;
    }
    100% {
      right: -310px;
      opacity: 0;
    }
  }

  @keyframes showing {
    0% {
      flex-basis: 0;
      opacity: 0;
    }
    100% {
      flex-basis: 310px;
      opacity: 1;
    }
  }
  @keyframes showing2 {
    0% {
      right: -310px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }
`;

const PageList = (props) => {
  const {
    selectedPage,
    selectedPages,
    pageMovingStatus,
    pageProps,
    pageChangeHandler,
    sortPagePropsHandler,
    multiplePageChangeHandler,
    setPageMovingStatus,
    onCreate,
    onCopy,
    onCut,
    onPaste,
    onDuplicate,
    onDelete,
    onMoveToTop,
    onMoveToBottom,
    setDragPageId,
    bundleStatus,
    setBundleStatus,
    pageMoving,
    setPageMoving,
    wsProperties,
    portfolios,
    amData,
    workspaceId,
    tocIndex,
    pageIdIndexMap,
    productProps,
    productIdProps,
    assetNames,
    assetTypes,
    updateDate,
  } = props;

  const sortedPageProps = useDnDSort({
    defaultItems: pageProps,
    sortPagePropsHandler,
    selectedPages,
    pageChangeHandler,
    multiplePageChangeHandler,
    pageMovingStatus,
    setPageMovingStatus,
    setDragPageId,
    bundleStatus,
    setBundleStatus,
    pageMoving,
    setPageMoving,
  });

  let top = 0;
  const tops = [];
  const firstSelectionIndex = sortedPageProps.findIndex(({ key }) =>
    selectedPages.includes(key)
  );

  if (bundleStatus === "BUNDLING3") {
    sortedPageProps.forEach((prop, i) => {
      if (
        i > 0 &&
        (!selectedPages.includes(prop.key) || i === firstSelectionIndex)
      ) {
        top += 166;
      }
      tops.push(top);
    });
  } else if (["BUNDLING2", "BUNDLING"].includes(bundleStatus)) {
    sortedPageProps.forEach((prop, i) => {
      if (i > 0) top += 166;
      tops.push(top);
    });
  } else {
    sortedPageProps.forEach((prop, i) => {
      if (
        (i > 0 &&
          (!pageMovingStatus ||
            (!pageMoving && ["BUBBLING", "STAY"].includes(bundleStatus)))) ||
        (i > 0 &&
          pageMovingStatus &&
          !(selectedPages.includes(prop.key) && i !== firstSelectionIndex))
      ) {
        top += 166;
      }
      tops.push(top);
    });
  }
  const pageListFrameClassName = [
    "page-list",
    pageMovingStatus ? "moving" : "",
  ].join(" ");

  return (
    <>
      <ContextMenu
        onCreate={onCreate}
        onCopy={onCopy}
        onCut={onCut}
        onPaste={onPaste}
        onDuplicate={onDuplicate}
        onDelete={onDelete}
        onMoveToTop={onMoveToTop}
        onMoveToBottom={onMoveToBottom}
      />

      <PageListFrame className={pageListFrameClassName}>
        {sortedPageProps.map((item, index) => {
          const top = tops[index] + "px";
          const rotate =
            !["BUBBLING", "STAY"].includes(bundleStatus) &&
            pageMovingStatus &&
            selectedPages.includes(item.key)
              ? (selectedPages.indexOf(item.key) * 8) / selectedPages.length
              : 0;
          const pageItemProps = {
            pageMovingStatus,
            item,
            templates,
            selectedPages,
            selectedPage,
            index,
            top,
            rotate,
            bundleStatus,
            setBundleStatus,
            wsProperties,
            portfolios,
            amData,
            workspaceId,
            tocIndex,
            pageIdIndexMap,
            productProps,
            productIdProps,
            assetNames,
            assetTypes,
            updateDate,
          };
          return <PageItem key={item.key} {...pageItemProps} />;
        })}
      </PageListFrame>
    </>
  );
};

const PageListFrame = styled.div`
  height: 80vh;
  overflow-y: scroll;
  position: relative;
  &::-webkit-scrollbar {
    width: 8px;
    margin-right: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5eced;
    border-radius: 4px;
    margin-right: 6px;
  }
`;

const PageItemFrame = styled.div`
  z-index: 0;
  user-select: none;
  margin: 12px 10px;
  padding: 10px 10px 10px 0;
  display: flex;
  align-items: center;
  background-color: transparent;

  &.selected {
    background-color: #92e0ff;
  }
  &.sub-selected {
    background-color: #d2f2ff;
  }

  .moving &.selected,
  .moving &.sub-selected {
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.2);
  }
`;

const PageNumber = styled.div`
  width: 40px;
  text-align: center;
  font-size: 14px;
  color: #192e55;

  .regular & {
    color: #9fa0a0;
  }
`;

const PageItem = (props) => {
  const {
    pageMovingStatus,
    item,
    templates,
    selectedPages,
    selectedPage,
    index,
    top,
    rotate,
    bundleStatus,
    setBundleStatus,
    wsProperties,
    portfolios,
    amData,
    workspaceId,
    tocIndex,
    pageIdIndexMap,
    productProps,
    productIdProps,
    assetNames,
    assetTypes,
    updateDate,
  } = props;
  const pageNumber = index + 1;
  const pageProp = item.value;

  // const renderer = ptTemplates[pageProp.template].renderer;
  const renderer = templates[pageProp.template].renderer;
  const chartTypeResizeMap = templates[pageProp.template].chartTypeResizeMap;
  const params = pageProp.params;
  const templateId = pageProp.template;
  const additionalClassName =
    selectedPages.indexOf(pageProp.pageId) >= 0
      ? selectedPage === pageProp.pageId
        ? "selected"
        : "sub-selected"
      : "regular";
  const className = ["page-list-item", additionalClassName].join(" ");

  const style = {
    top,
    position: "absolute",
    transition: "all 80ms",
    transform: `rotate(${rotate}deg)`,
    transformOrigin: "center",
  };
  if (bundleStatus === "BUNDLING2") {
    requestAnimationFrame(() => {
      setBundleStatus("BUNDLING3");
    });
  } else if (bundleStatus === "BUNDLING3") {
    const origPos = index * 166;
    const diff = origPos - parseInt(top);
    style.transform = `translate3d(0, ${-diff}px, 0)`;
    style.transition = "all 300ms";
    requestAnimationFrame(() => {
      style.transform = "";
      setBundleStatus("BUNDLED");
    });
  }

  return (
    <PageItemFrame
      id={item.key}
      className={className}
      style={style}
      {...item.events}
    >
      <PageNumber>{pageNumber}</PageNumber>
      <div
        className="page-list-item-content"
        style={{
          position: "relative",
          height: "35.35mm",
          width: "50mm",
          overflow: "clip",
          backgroundColor: "white",
          border:
            selectedPage === pageProp.pageId ? "2px solid #2397CE" : "unset",
          boxShadow: "0 0 10px -2px rgba(147, 163, 169, 0.8)",
        }}
      >
        <Thumbnail scale={0.168}>
          <PageTemplate
            params={{ ...params, wsProperties }}
            renderer={renderer}
            chartTypeResizeMap={chartTypeResizeMap}
            templateId={templateId}
            portfolios={portfolios}
            amData={amData}
            workspaceId={workspaceId}
            tocIndex={tocIndex}
            pageIdIndexMap={pageIdIndexMap}
            pageIndex={index}
            productProps={productProps}
            productIdProps={productIdProps}
            assetNames={assetNames}
            assetTypes={assetTypes}
            updateDate={updateDate}
          />
        </Thumbnail>
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: 9999,
            width: "100%",
            height: "100%",
          }}
        ></div>
      </div>
    </PageItemFrame>
  );
};

/**
 * Sample component to demonstrate the usage of the PageEditor with templates
 */

const removeElementFromArray = (array, element) => {
  const newArray = [...array];
  const index = newArray.indexOf(element);
  if (index >= 0) {
    newArray.splice(index, 1);
  }
  return newArray;
};

const generatePageKeyIndex = (array) => {
  return array.reduce((acc, item, index) => {
    acc[item.pageId] = index;
    return acc;
  }, {});
};

const getData = async (location, viewMode, printMode) => {
  const path = viewMode
    ? printMode
      ? "/workspace/:workspaceId/report/print"
      : "/workspace/:workspaceId/report"
    : "/workspace/:workspaceId/report/edit";
  const match = matchPath(path, location.pathname);
  const workspaceId = match?.params?.workspaceId;

  const workspace = await getWorkspace(workspaceId);
  // wsProperties
  const dept = await getBusyoByCustomerId(workspace.customerId);
  const wsProperties = {
    ...workspace,
    dept,
  };
  // ベンチマークID => ベンチマーク情報マップ
  const benchmarkIdMap = await getBenchmarksIdMapByCustomerId(
    workspace.client_id
  );

  // プロダクトマスタ
  // const productMasterWS = await getProductsForCustomerByCustomerId(
  //   workspace.customerId
  // );
  const productMasterWS = await getProductsForWSByWSId(workspaceId);

  const productMaster = new Map();
  productMasterWS.forEach((products, asset_type) => {
    productMaster.set(
      asset_type,
      products.map(({ product_name }) => product_name)
    );
  });

  // productProps
  const productProps = {};
  const productIdProps = {};
  productMasterWS.forEach((products, asset_type) => {
    products.forEach(
      ({
        asset_type,
        asset_type_attributes,
        introduce_purpose,
        asset_management_company,
        product_id,
        product_name,
        supplemental_product_name,
        intention,
        risk,
        return: returnValue,
        upper_limit,
      }) => {
        const assets = asset_type
          ? [asset_type]
          : asset_type_attributes?.map(({ asset_type }) => asset_type);
        const primaryAsset = asset_type
          ? asset_type
          : asset_type_attributes?.find(({ assetAttrs }) => assetAttrs?.default)
              ?.asset_type || assets?.length > 0
          ? assets[0]
          : "";

        const productInfo = {
          asset: primaryAsset,
          assets: assets,
          introduce_purpose,
          operator_company: asset_management_company,
          product_id,
          product_name,
          product_name_option: supplemental_product_name
            ? h2z(supplemental_product_name)
            : "",
          purpose: intention,
          risk: `${new BigNumber(risk).toFixed(2)}%`,
          return: `${new BigNumber(returnValue).toFixed(2)}%`,
          upper_limit,
        };
        productProps[product_name] = productInfo;
        productIdProps[product_id] = productInfo;
      }
    );
  });
  // productMasterWPurpose
  const productMasterWPurpose = new Map();
  productMasterWS.forEach((products, asset_type) => {
    const map = new Map();
    productMasterWPurpose.set(asset_type, map);
    products.forEach(({ product_name, introduce_purpose }) => {
      map.set(product_name, introduce_purpose);
    });
  });
  const generatePort = (port) => {
    const { compositions, id, name } = port;
    const assigns = new Map();
    const result = {
      selected: true,
      reflected: true,
      assetProductAssigns: assigns,
      id,
    };
    compositions?.forEach(({ amount, asset_type, product_id }) => {
      const productInfo = productIdProps[product_id];
      if (!assigns.has(asset_type)) {
        assigns.set(asset_type, new Map());
      }
      const assignInfo = {
        assign: amount,
        oldAssign: amount,
        reflected: true,
        product_id: productInfo.product_id,
      };
      const productKey = productInfo.product_name;
      assigns.get(asset_type).set(productKey, assignInfo);
    });
    return { id, name, port: result };
  };

  const portfolios = new Map();
  const curPort = workspace?.portfolio_set?.current_portfolio;
  if (curPort) {
    const curPortInfo = generatePort(curPort);
    portfolios.set(curPortInfo.name, curPortInfo.port);
  }
  workspace?.portfolio_set?.proposed_portfolios
    ?.sort((a, b) => a.name.localeCompare(b.name))
    .forEach((port) => {
      const portInfo = generatePort(port);
      portfolios.set(portInfo.name, portInfo.port);
    });

  // 政策AMと選択済みプロダクトリスト
  const assetMix = new Map();
  workspace.policy_asset_mix.asset_compositions.forEach(
    ({ asset_type, benchmark_compositions, ratio }) => {
      const values = new Map();
      assetMix.set(asset_type, values);
      values.set("assign", `${ratio * 100} %`);
      const firstBM =
        !benchmark_compositions || benchmark_compositions.length <= 0
          ? null
          : benchmark_compositions[0];
      const firstBMId = !firstBM ? null : firstBM.benchmark_id;
      const numBMs = benchmark_compositions.length;
      const firstBMName = !firstBMId
        ? ""
        : firstBMId in benchmarkIdMap
        ? benchmarkIdMap[firstBMId].product_name
        : "";
      const bmText = firstBMName + (numBMs > 1 ? ` 他${numBMs - 1}件` : "");
      values.set("bm", bmText);
      values.set("products", []);
      values.set("introducePurposes", {});
      values.set("productsIndex", {});
    }
  );
  workspace?.portfolio_set?.selected_products?.forEach(
    ({ asset_type, intention, product_id, index }) => {
      const productInfo = productIdProps[product_id];
      const values = assetMix.get(asset_type);
      if (!values.has("products")) {
        values.set("products", []);
      }
      if (index > 0) {
        values.get("productsIndex")[productInfo.product_name] = index;
      }
      values.get("products").push(productInfo.product_name);
      if (!values.has("introducePurposes")) {
        values.set("introducePurposes", {});
      }
      values.get("introducePurposes")[productInfo.product_name] = intention;
    }
  );

  const customerId = wsProperties.customerId;
  const assetMixId = wsProperties.assetMixId;
  const report = workspace.report;
  const assetNames = await getAssetNamingByCustomerId(workspace.customerId);
  const assetTypes = await sortAssetsByAssetTypeStandardOrder(
    Object.keys(assetNames)
  );

  console.log("assetNames", assetNames);

  return {
    report,
    wsProperties,
    assetMix,
    portfolios,
    productMasterWPurpose,
    workspaceId,
    assetNames,
    assetTypes,
    workspace,
    productProps,
    productIdProps,
    updateDate: workspace?.updateDate,
  };
};

const PageEditorContext = createContext({ viewMode: false });

const PageEditorSample = (props) => {
  const { viewMode = false, printMode = false } = props;
  const [viewStyle, setViewStyle] = useState(
    viewMode ? (printMode ? "tile" : "onePage") : null
  );

  const [selectedPage, selectPage] = useState(null);
  const [selectedPages, selectPages] = useState([]);
  const [pageProps, setPageProps] = useState([]);
  const updatePageProps = async (newPageProps) => {
    if (workspace.frozen) {
      console.log("updatePageProps skipped because workspace is frozen");
      return;
    }
    const tocIndexObject = Object.fromEntries(tocIndex.entries());
    await updateReport({
      workspaceId,
      report: JSON.stringify({
        pageProps: newPageProps,
        tocIndex: tocIndexObject,
      }),
    });
  };
  const [pageIdIndexMap, setPageIdIndexMap] = useState(
    generatePageKeyIndex([])
  );

  const [templateId, setTemplateId] = useState(null);
  const [wsProperties, setWsProperties] = useState({
    customer: null,
    customerId: null,
    ws: null,
    am: null,
    assetMixId: null,
  });
  const [params, setParams] = useState({});

  const location = useLocation();

  const [error, setError] = useState(null);
  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  const [portfolios, setPortfolios] = useState(null);
  const [amData, setAmData] = useState(null);
  const [chartDefinitions, setChartDefinitions] = useState(null);
  const [tocIndex, setTocIndex] = useState(null);
  const [workspaceId, setWorkspaceId] = useState(null);
  const [analysisResult, setAnalysisResult] = useState(null);
  const [assetNames, setAssetNames] = useState(null);
  const [assetTypes, setAssetTypes] = useState(null);
  const [workspace, setWorkspace] = useState(null);
  const [productProps, setProductProps] = useState(null);
  const [productIdProps, setProductIdProps] = useState(null);
  const [updateDate, setUpdateDate] = useState(null);

  useEffect(() => {
    getData(location, viewMode, printMode)
      .then((data) => {
        setProductProps(data.productProps);
        setProductIdProps(data.productIdProps);
        setUpdateDate(data.updateDate);

        const portfoliosWPurpose = mergePurpose(
          data.portfolios,
          data.productMasterWPurpose
        );
        setWsProperties(data.wsProperties);
        const report =
          !ANYTIME_AUTO_CREATION_MODE && data.report
            ? JSON.parse(data.report)
            : createDefaultReport(portfoliosWPurpose, data.wsProperties);

        const { pageProps: initProps, tocIndex } = report;
        if (!data.report && !data.workspace.frozen) {
          updateReport({
            workspaceId: data.workspaceId,
            report: JSON.stringify(report),
          });
        } else {
          console.log("updateReport in getData is skipped");
          if (data.report) {
            console.log(
              "updateReport in getData skipped because data.report exists"
            );
          }
          if (data.workspace.frozen) {
            console.log(
              "updateReport in getData skipped because workspace is frozen"
            );
          }
        }
        const newChartDefinitions =
          generateChartDefinitions(portfoliosWPurpose);
        setChartDefinitions(newChartDefinitions);

        setPortfolios(portfoliosWPurpose);
        setAmData(data.assetMix);
        const initPage = initProps[0].pageId;
        selectPage(initPage);
        selectPages([initPage]);
        setPageProps(initProps);
        setTocIndex(new Map(Object.entries(tocIndex)));
        const initPageKeyIndex = generatePageKeyIndex(initProps);
        setPageIdIndexMap(initPageKeyIndex);
        setTemplateId(initProps[initPageKeyIndex[initPage]]?.template);
        setParams(initProps[initPageKeyIndex[initPage]]?.params);
        setWorkspaceId(data.workspaceId);
        setAnalysisResult(data.analysisResult);
        setAssetNames(data.assetNames);
        setAssetTypes(data.assetTypes);
        setWorkspace(data.workspace);
      })
      .catch((err) => {
        setError({ error: err, status: 404 });
      });
  }, []);

  useEffect(() => {
    if (!workspaceId) return;
    getAnalysisResult(workspaceId).then((analysisResult) => {
      setAnalysisResult(analysisResult);
    });
  }, [workspaceId, portfolios]);

  const changeTemplateId = (newTemplateId) => {
    setTemplateId(newTemplateId);
    const pageIndex = pageIdIndexMap[selectedPage];
    const oldTemplateId = pageProps[pageIndex].template;
    const newPageProps = [...pageProps];
    newPageProps[pageIndex].template = newTemplateId;
    updatePageProps(newPageProps);

    const newParamsBase = Object.assign({}, params);
    const defaultParams =
      "defaultParams" in templates[newTemplateId]
        ? templates[newTemplateId].defaultParams
        : {};
    const newParams = Object.assign({}, defaultParams, newParamsBase);
    setParams(newParams);
    const command = {
      type: "changePageTemplate",
      pageId: selectedPage,
      oldTemplateId,
      newTemplateId,
    };
    recordCommand(command);
  };

  const renderer = templates[templateId]?.renderer;
  const chartTypeResizeMap = templates[templateId]?.chartTypeResizeMap;

  const [pageMovingStatus, setPageMovingStatus] = useState(false);
  const [pageMoving, setPageMoving] = useState(false);
  const [clipboard, setClipboard] = useState(null);

  const changeHandlerCore = (newParams) => {
    setParams(newParams);
    const newPageProps = [...pageProps];
    const pageIndex = pageIdIndexMap[selectedPage];
    const oldParams = pageProps[pageIndex].params;
    newPageProps[pageIndex].params = newParams;
    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
    return oldParams;
  };
  /**
   * 特定ページのプロパティ変更処理ハンドラ
   */
  const changeHandler = (newParams) => {
    const oldParams = changeHandlerCore(newParams);
    const changedParamKey = extractChangedParamKey(oldParams, newParams);

    const command = {
      type: "changePageParam",
      pageId: selectedPage,
      key: changedParamKey,
      newParams: newParams,
      oldParams: oldParams,
    };

    const lastCommand =
      commandHistory.length > 0
        ? commandHistory[commandHistory.length - 1]
        : null;

    if (
      lastCommand &&
      lastCommand.type === "changePageParam" &&
      lastCommand.key === command.key &&
      lastCommand.pageId === selectedPage
    ) {
      if ("text" in newParams[changedParamKey]) {
        const diff = extractDiff(
          lastCommand.newParams[changedParamKey].text,
          newParams[changedParamKey].text
        );
        if (diff.result === "OneLineChanged") {
          lastCommand.newParams[changedParamKey].text =
            newParams[changedParamKey].text;
          return;
        }
      }
    }
    recordCommand(command);
  };

  const compareParam = (param1, param2) => {
    return Array.from(Object.entries(param1))
      .map(([k, v]) => v === param2[k])
      .every((v) => v);
  };

  const extractChangedParamKey = (oldParams, newParams) => {
    for (const key in newParams) {
      if (!compareParam(newParams[key], oldParams[key])) {
        return key;
      }
    }
    return null;
  };

  const zip = (a, b) => {
    return a.map((v, i) => [v, b[i]]);
  };

  const extractDiff = (oldText, newText) => {
    const oldLines = oldText.split("\n");
    const newLines = newText.split("\n");
    if (oldLines.length !== newLines.length) {
      return { result: "NumLinesChanged" };
    }
    const zipped = zip(oldLines, newLines);
    const checked = zipped.map(([oldLine, newLine]) => oldLine === newLine);
    const numLinesChanged = checked.filter((v) => !v).length;
    if (numLinesChanged === 1) {
      return {
        result: "OneLineChanged",
        line: zipped.findIndex(([oldLine, newLine]) => oldLine !== newLine),
      };
    }
    return { result: "MultipleLinesChanged" };
  };

  /**
   * ページプロパティのDnDによるソート処理後に、ページと一の対応関係を更新するハンドラ
   */
  const sortPagePropsHandler = (sortedPageProps) => {
    setPagePropsSorted(sortedPageProps); // the latest pageProps can't be accessed here somehow.
  };

  const sortPagePropsHandlerCore = (sortedPageProps) => {
    setPageProps(sortedPageProps);
    updatePageProps(sortedPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(sortedPageProps);
    setPageIdIndexMap(newPageIdIndexMap);

    const command = {
      type: "movePage",
      oldPageIdList: pageProps.map(({ pageId }) => pageId),
      newPageIdList: sortedPageProps.map(({ pageId }) => pageId),
    };
    if (command.oldPageIdList.join() !== command.newPageIdList.join()) {
      setSortMoved(command);
    }
  };
  const [pagePropsSorted, setPagePropsSorted] = useState(null);

  useEffect(() => {
    if (pagePropsSorted) {
      sortPagePropsHandlerCore(pagePropsSorted);
      setPagePropsSorted(null);
    }
  }, [pagePropsSorted]);

  const [sortMoved, setSortMoved] = useState(null);
  useEffect(() => {
    if (sortMoved) {
      recordCommand(sortMoved);
      setSortMoved(null);
    }
  }, [sortMoved]);

  /**
   * ページ選択イベント処理ハンドラ
   * @param {*} newPage 選択されたページID
   * @param {*} additional metaキーも同時におされている場合true
   */
  const pageChangeHandler = (newPage, additional = false) => {
    if (additional) {
      if (selectedPages.indexOf(newPage) >= 0) {
        if (selectedPages.length === 1) {
          return selectedPages;
        } else {
          const newSelectedPages = removeElementFromArray(
            selectedPages,
            newPage
          );
          const newSelectedPage = newSelectedPages[newSelectedPages.length - 1];
          selectPage(newSelectedPage);
          selectPages(newSelectedPages);
          applyNewPageToTemplateAndParams(newSelectedPage);
          return newSelectedPages;
        }
      } else {
        selectPage(newPage);
        applyNewPageToTemplateAndParams(newPage);
        const newSelectedPages = [...selectedPages];
        newSelectedPages.push(newPage);
        selectPages(newSelectedPages);
        return newSelectedPages;
      }
    } else {
      selectPage(newPage);
      selectPages([newPage]);
      applyNewPageToTemplateAndParams(newPage);
      return [newPage];
    }
  };

  const copyPageHandler = () => {
    const pageIndices = selectedPages.map((p) => pageIdIndexMap[p]);
    const pagePropList = pageIndices.map((pageIndex) => pageProps[pageIndex]);
    setClipboard(pagePropList);
  };

  const cutPageHandler = () => {
    copyPageHandler();
    deletePageHandler();
  };

  const generateNexPageId = () => {
    const pageIds = pageProps.map((prop) => prop.pageId);
    const maxPageId = Math.max(...pageIds);
    return maxPageId + 1;
  };

  const pastePageHandler = () => {
    const index = pageIdIndexMap[selectedPage];
    const newPageProps = [...pageProps];
    const newPageId = generateNexPageId();
    const newPagePropList = clipboard.map((clipPageProp, i) => {
      const newPageProp = Object.assign({}, clipPageProp, {
        pageId: newPageId + i,
      });
      return newPageProp;
    });
    const newSelectedPages = newPagePropList.map((p) => p.pageId);

    newPageProps.splice(index + 1, 0, ...newPagePropList);
    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
    setCreatedPageIds(newSelectedPages);

    const command = {
      type: "addPage",
      index,
      pagePropsAdded: newPagePropList,
    };
    recordCommand(command);
  };

  const [willDuplicate, setWillDuplicate] = useState(false);

  const duplicateHandler = () => {
    copyPageHandler();
    setWillDuplicate(true);
  };

  const deletePageHandler = () => {
    if (Object.keys(pageProps).length <= 1) {
      alert("レポートは1ページ以上必要なため、削除できません。");
      return;
    }
    const pageIndex = pageIdIndexMap[selectedPage];
    const pageIdListBeforeDelete = pageProps.map((p) => p.pageId);

    const lowerPageIds = pageProps
      .filter((_, i) => i < pageIndex)
      .map(({ pageId }) => pageId)
      .filter((pid) => selectedPages.indexOf(pid) < 0);
    const higherPageIds = pageProps
      .filter((_, i) => i > pageIndex)
      .map(({ pageId }) => pageId)
      .filter((pid) => selectedPages.indexOf(pid) < 0);
    const lowerCandidate =
      lowerPageIds.length > 0 ? lowerPageIds[lowerPageIds.length - 1] : null;
    const higherCandidate = higherPageIds.length > 0 ? higherPageIds[0] : null;
    const newSelectedPage =
      !!lowerCandidate && lowerCandidate >= 0
        ? lowerCandidate
        : higherCandidate;

    const newPageProps = [...pageProps].filter(
      ({ pageId }) => selectedPages.indexOf(pageId) < 0
    );

    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
    setCreatedPageIds([newSelectedPage]);

    const command = {
      type: "deletePage",
      pageIdListBeforeDelete,
      pagePropsDeleted: selectedPages.map((pageId) =>
        Object.assign({}, pageProps[pageIdIndexMap[pageId]])
      ),
    };
    recordCommand(command);
  };

  useEffect(() => {
    if (willDuplicate) {
      pastePageHandler();
      setWillDuplicate(false);
    }
  }, [willDuplicate]);

  const moveToTopHandler = () => {
    const tmpPageProps = [...pageProps];
    const newPagePropsFiltered = tmpPageProps.filter(
      ({ pageId }) => selectedPages.indexOf(pageId) < 0
    );

    const pageIndices = selectedPages.map((p) => pageIdIndexMap[p]);
    const pagePropList = pageIndices.map(
      (pageIndex) => tmpPageProps[pageIndex]
    );
    const newPageProps = [...pagePropList, ...newPagePropsFiltered];

    setPageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
    const command = {
      type: "movePage",
      oldPageIdList: pageProps.map(({ pageId }) => pageId),
      newPageIdList: newPageProps.map(({ pageId }) => pageId),
    };
    recordCommand(command);
  };

  const moveToBottomHandler = () => {
    const tmpPageProps = [...pageProps];
    const newPagePropsFiltered = tmpPageProps.filter(
      ({ pageId }) => selectedPages.indexOf(pageId) < 0
    );

    const pageIndices = selectedPages.map((p) => pageIdIndexMap[p]);
    const pagePropList = pageIndices.map(
      (pageIndex) => tmpPageProps[pageIndex]
    );
    const newPageProps = [...newPagePropsFiltered, ...pagePropList];

    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
    const command = {
      type: "movePage",
      oldPageIdList: pageProps.map(({ pageId }) => pageId),
      newPageIdList: newPageProps.map(({ pageId }) => pageId),
    };
    recordCommand(command);
  };

  const createPageHandler = () => {
    const newPageId = generateNexPageId();
    const index = pageIdIndexMap[selectedPage];
    const newPageProps = [...pageProps];
    const newPageProp = {
      pageId: newPageId,
      template: "oneChart",
      params: {
        titleProps: {
          text: "新規ページ",
        },
        chart1Props: {
          chartType: "empty",
        },
        commentProps: {
          text: "コメント",
        },
      },
    };
    newPageProps.splice(index + 1, 0, newPageProp);
    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
    setCreatedPageIds([newPageId]);

    const command = {
      type: "addPage",
      index,
      pagePropsAdded: [newPageProp],
    };
    recordCommand(command);
  };

  const [createdPageIds, setCreatedPageIds] = useState([]);
  useEffect(() => {
    if (createdPageIds.length > 0) {
      selectPage(createdPageIds[0]);
      selectPages(createdPageIds);
      applyNewPageToTemplateAndParams(createdPageIds[0]);
      setCreatedPageIds([]);
    }
  }, [createdPageIds]);

  const onCreate = (e) => {
    e.preventDefault();
    createPageHandler();
  };

  const onPrint = (e) => {
    e.preventDefault();
    window.open(`/workspace/${workspaceId}/report/print`);
  };

  const onRefresh = (e) => {
    e.preventDefault();
    if (workspace.frozen) {
      console.log("onRefresh skipped because workspace is frozen");
      return;
    }
    const newReport = createDefaultReport(portfolios, wsProperties);
    const { pageProps: newPageProps, tocIndex: newTocIndex } = newReport;
    return updateReport({
      workspaceId: workspaceId,
      report: JSON.stringify(newReport),
    })
      .then((result) => {
        console.log("updateReport result", result);
        const initPage = newPageProps[0].pageId;
        selectPage(initPage);
        selectPages([initPage]);
        setPageProps(newPageProps);
        setTocIndex(new Map(Object.entries(newTocIndex)));
        const initPageKeyIndex = generatePageKeyIndex(newPageProps);
        setPageIdIndexMap(initPageKeyIndex);
        setTemplateId(newPageProps[initPageKeyIndex[initPage]]?.template);
        setParams(newPageProps[initPageKeyIndex[initPage]]?.params);

        setCommandHistory([]);
        setRedoCommandHistory([]);
      })
      .catch((err) => {
        console.log("updateReport error", err);
      });
  };

  const onCopy = (e) => {
    e.preventDefault();
    copyPageHandler();
  };

  const onCut = (e) => {
    e.preventDefault();
    cutPageHandler();
  };

  const onPaste = (e) => {
    e.preventDefault();
    pastePageHandler();
  };

  const onDuplicate = (e) => {
    e.preventDefault();
    duplicateHandler();
  };

  const onDelete = (e) => {
    e.preventDefault();
    deletePageHandler();
  };

  const onMoveToTop = (e) => {
    e.preventDefault();
    moveToTopHandler();
  };

  const onMoveToBottom = (e) => {
    e.preventDefault();
    moveToBottomHandler();
  };

  const multiplePageChangeHandler = (newSelectedPage, newSelectedPages) => {
    selectPage(newSelectedPage);
    selectPages(newSelectedPages);
    applyNewPageToTemplateAndParams(newSelectedPage);
  };

  const [commandHistory, setCommandHistory] = useState([]);
  const [redoCommandHistory, setRedoCommandHistory] = useState([]);

  const recordCommand = (command) => {
    setRedoCommandHistory([]);
    const newCommandHistory = [...commandHistory, command];
    setCommandHistory(newCommandHistory);
  };

  const [changingTemplateId, setChangingTemplateId] = useState(null);

  const undoChangePageTemplate = (params) => {
    const { pageId, oldTemplateId } = params;
    const newPageProps = [...pageProps];
    const index = pageIdIndexMap[pageId];
    newPageProps[index].template = oldTemplateId;
    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    selectPage(pageId);
    setChangingTemplateId(oldTemplateId);
  };

  const redoChangePageTemplate = (params) => {
    const { pageId, newTemplateId } = params;
    const newPageProps = [...pageProps];
    const index = pageIdIndexMap[pageId];
    newPageProps[index].template = newTemplateId;
    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    selectPage(pageId);
    setChangingTemplateId(newTemplateId);
  };
  useEffect(() => {
    if (changingTemplateId !== null) {
      setTemplateId(changingTemplateId);
      setChangingTemplateId(null);
    }
  }, [changingTemplateId]);

  const [changeParamParams, setChangeParamParams] = useState(null);

  const undoChangePageParam = (params) => {
    const { pageId, oldParams } = params;
    setChangeParamParams(oldParams);
    selectPage(pageId);
  };
  const redoChangePageParam = (params) => {
    const { pageId, newParams } = params;
    setChangeParamParams(newParams);
    selectPage(pageId);
  };

  useEffect(() => {
    if (changeParamParams) {
      changeHandlerCore(changeParamParams);
      setChangeParamParams(null);
    }
  }, [changeParamParams]);

  const redoAddPage = (params) => {
    const { index, pagePropsAdded } = params;

    const newPageProps = [...pageProps];
    const newPageId = generateNexPageId();
    const newPagePropList = pagePropsAdded.map((pageProp, i) => {
      const pageId = newPageId + i;
      const newPageProp = Object.assign({}, pageProp, {
        pageId,
      });
      pageProp.pageId = pageId;
      return newPageProp;
    });
    const newSelectedPages = newPagePropList.map((p) => p.pageId);

    newPageProps.splice(index + 1, 0, ...newPagePropList);
    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
    setCreatedPageIds(newSelectedPages);
  };

  const undoAddPage = (params) => {
    const { pagePropsAdded } = params;
    const pageIdsToDelete = pagePropsAdded.map(({ pageId }) => pageId);

    const pageIndex = pageIdIndexMap[selectedPage];

    const lowerPageIds = pageProps
      .filter((_, i) => i < pageIndex)
      .map(({ pageId }) => pageId)
      .filter((pid) => pageIdsToDelete.indexOf(pid) < 0);
    const higherPageIds = pageProps
      .filter((_, i) => i > pageIndex)
      .map(({ pageId }) => pageId)
      .filter((pid) => pageIdsToDelete.indexOf(pid) < 0);
    const lowerCandidate =
      lowerPageIds.length > 0 ? lowerPageIds[lowerPageIds.length - 1] : null;
    const higherCandidate = higherPageIds.length > 0 ? higherPageIds[0] : null;
    const newSelectedPage =
      !!lowerCandidate && lowerCandidate >= 0
        ? lowerCandidate
        : higherCandidate;

    const newPageProps = [...pageProps].filter(
      ({ pageId }) => pageIdsToDelete.indexOf(pageId) < 0
    );

    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
    setCreatedPageIds([newSelectedPage]);
  };

  const undoDeletePage = (params) => {
    const { pageIdListBeforeDelete, pagePropsDeleted } = params;

    const tmpPageProps = [...pageProps];
    const tmpPageIdIndexMap = generatePageKeyIndex(tmpPageProps);
    const deletedPageIdIndexMap = generatePageKeyIndex(pagePropsDeleted);

    const newPageProps = [];

    pageIdListBeforeDelete.forEach((pageId, i) => {
      const index = tmpPageIdIndexMap[pageId];
      const deletedIndex = deletedPageIdIndexMap[pageId];
      const pageProp =
        index !== undefined
          ? tmpPageProps[index]
          : pagePropsDeleted[deletedIndex];
      newPageProps.push(pageProp);
    });

    const newSelectedPages = pagePropsDeleted.map(({ pageId }) => pageId);

    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
    setCreatedPageIds(newSelectedPages);
  };

  const redoDeletePage = (params) => {
    const { pagePropsDeleted } = params;
    const pageIdsToDelete = pagePropsDeleted.map(({ pageId }) => pageId);

    const pageIndex = pageIdIndexMap[selectedPage];

    const lowerPageIds = pageProps
      .filter((_, i) => i < pageIndex)
      .map(({ pageId }) => pageId)
      .filter((pid) => pageIdsToDelete.indexOf(pid) < 0);
    const higherPageIds = pageProps
      .filter((_, i) => i > pageIndex)
      .map(({ pageId }) => pageId)
      .filter((pid) => pageIdsToDelete.indexOf(pid) < 0);
    const lowerCandidate =
      lowerPageIds.length > 0 ? lowerPageIds[lowerPageIds.length - 1] : null;
    const higherCandidate = higherPageIds.length > 0 ? higherPageIds[0] : null;
    const newSelectedPage =
      !!lowerCandidate && lowerCandidate >= 0
        ? lowerCandidate
        : higherCandidate;

    const newPageProps = [...pageProps].filter(
      ({ pageId }) => pageIdsToDelete.indexOf(pageId) < 0
    );

    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
    setCreatedPageIds([newSelectedPage]);
  };

  const undoMovePage = (params) => {
    const { oldPageIdList } = params;
    const newPageProps = [];
    oldPageIdList.forEach((pageId, i) => {
      const index = pageIdIndexMap[pageId];
      const pageProp = pageProps[index];
      newPageProps.push(pageProp);
    });
    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
  };

  const redoMovePage = (params) => {
    const { newPageIdList } = params;
    const newPageProps = [];
    newPageIdList.forEach((pageId, i) => {
      const index = pageIdIndexMap[pageId];
      const pageProp = pageProps[index];
      newPageProps.push(pageProp);
    });
    setPageProps(newPageProps);
    updatePageProps(newPageProps);
    const newPageIdIndexMap = generatePageKeyIndex(newPageProps);
    setPageIdIndexMap(newPageIdIndexMap);
  };

  const commandMap = {
    addPage: {
      redo: redoAddPage,
      undo: undoAddPage,
    },
    deletePage: {
      undo: undoDeletePage,
      redo: redoDeletePage,
    },
    movePage: {
      undo: undoMovePage,
      redo: redoMovePage,
    },
    changePageParam: {
      undo: undoChangePageParam,
      redo: redoChangePageParam,
    },
    changePageTemplate: {
      undo: undoChangePageTemplate,
      redo: redoChangePageTemplate,
    },
  };

  const undo = () => {
    const command = commandHistory.pop();
    const { type, ...params } = command;
    const { undo } = commandMap[type];
    undo(params);
    const newCommandHistory = [...commandHistory];
    setCommandHistory(newCommandHistory);
    const newRedoCommandHistory = [...redoCommandHistory, command];
    setRedoCommandHistory(newRedoCommandHistory);
  };

  const redo = () => {
    const command = redoCommandHistory.pop();
    const { type, ...params } = command;
    const { redo } = commandMap[type];
    redo(params);
    const newRedoCommandHistory = [...redoCommandHistory];
    setRedoCommandHistory(newRedoCommandHistory);
    const newCommandHistory = [...commandHistory, command];
    setCommandHistory(newCommandHistory);
  };

  /**
   * ページ選択後のテンプレートとパラメータをPageEditorにセットする処理
   * @param {*} newPage 新規ページID
   */
  const applyNewPageToTemplateAndParams = (newPage) => {
    const newPageIndex = pageIdIndexMap[newPage];
    const newTemplateId = pageProps[newPageIndex].template;
    setTemplateId(newTemplateId);
    const newParamsBase = pageProps[newPageIndex].params;
    const defaultParams =
      "defaultParams" in templates[newTemplateId]
        ? templates[newTemplateId].defaultParams
        : {};
    const newParams = Object.assign({}, defaultParams, newParamsBase);
    setParams(newParams);
  };

  const [dragPageId, setDragPageId] = useState(null);
  const [bundleStatus, setBundleStatus] = useState("STAY"); // STAY, BUNDLING, BUNDLED, BUBBLING

  const removeItem = (props, item) => {
    const index = props.findIndex(({ pageId }) => pageId === item.pageId);
    if (index >= 0) {
      props.splice(index, 1);
    }
  };
  const removeItems = (props, items) => {
    items.forEach((item) => {
      removeItem(props, item);
    });
  };

  useEffect(() => {
    if (
      pageMovingStatus &&
      dragPageId !== null &&
      bundleStatus === "BUNDLING" // &&  !pageMoving
    ) {
      const newPageProps = [...pageProps];
      const dragPageIndexBeforeRemove = newPageProps.findIndex(
        ({ pageId }) => pageId === dragPageId
      );
      const movingItems = newPageProps.filter(({ pageId }) =>
        selectedPages.includes(pageId)
      );
      removeItems(newPageProps, movingItems);
      const dragPageIndex = Math.min(
        dragPageIndexBeforeRemove,
        newPageProps.length
      );
      newPageProps.splice(dragPageIndex, 0, ...movingItems);
      sortPagePropsHandler(newPageProps);
      setBundleStatus("BUNDLING2");
    }
  }, [pageMovingStatus, dragPageId, bundleStatus, pageMoving]);

  // console.log("commandHistory", commandHistory); // when you want to debug around command history, undo and redo, uncomment this line
  const tileMode = viewMode && viewStyle === "tile";

  if (printMode) {
    return (
      <PageEditorContext.Provider
        value={{ viewMode, viewStyle, analysisResult, assetNames }}
      >
        <style>{`
        .holder {
          display:none
        }
        .print_pages {
            box-sizing: content-box !important;
            width: calc( 297mm - 1mm ) !important;
            height: calc( 210mm - 1mm ) !important;
            box-shadow: none !important;
            margin: 10px !important;
            float: none;
            overflow: hidden !important;
        }
        .print_pages > div {
            box-sizing: content-box !important;
            width: calc( 297mm - 1mm - 80px ) !important;
            height: calc( 210mm - 1mm - 53px ) !important;
    
        }
        .print_pages > div.cover-page-container {
          width: calc(297mm - 1mm) !important;
          box-sizing: border-box !important;
          max-width: calc(297mm - 1mm) !important;
        }
        .print_pages  *{
            max-width: calc( 297mm - 1mm - 80px ) !important;
            max-height: calc( 210mm - 1mm - 53px ) !important;
        }
        .print_pages .cover-page-container *{
          max-width: calc(297mm - 1mm) !important;
        }
        .print_pages:last-child {
          page-break-after: avoid;
        }
        .print_pages div {
          page-break-after: avoid;
        }

        @media print {
          .print_pages {
            margin: 0 !important;
          }
          .no-print-area {
            display: none;
          }
          .tile-viewer-container {
            background-color: white !important;
          }
        }
      `}</style>
        <div
          className="no-print-area"
          style={{
            backgroundColor: "rgb(229, 237, 241)",
            padding: 10,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1000,
            width: "100%",
          }}
        >
          <PrintButton onClick={(e) => window.print()}>
            印刷 / PDFファイル保存
          </PrintButton>
        </div>
        <div className="no-print-area" style={{ height: 50 }} />

        <PageTileViewer
          pageProps={pageProps}
          wsProperties={wsProperties}
          portfolios={portfolios}
          amData={amData}
          workspaceId={workspaceId}
          tocIndex={tocIndex}
          pageIdIndexMap={pageIdIndexMap}
          printMode={printMode}
          productProps={productProps}
          productIdProps={productIdProps}
          assetNames={assetNames}
          assetTypes={assetTypes}
          updateDate={updateDate}
        />
      </PageEditorContext.Provider>
    );
  }

  return (
    <PageEditorContext.Provider
      value={{ viewMode, viewStyle, analysisResult, assetNames }}
    >
      <WSFrame
        currentStep={4}
        step1Value={wsProperties.customer}
        step2Value={wsProperties.ws}
        step3Value={wsProperties.am}
        factorYearRevisionVersion={wsProperties.factor_version}
        mode="report"
      >
        <div
          style={{
            display: "flex",
            flexDirection: tileMode ? "column" : "row",
          }}
        >
          <div
            className="ws-report-left-side-area"
            // このdivが、編集ボタンとサムネページリストを含む左側のエリア
            style={{
              width: tileMode ? "100%" : "300px",
              margin: "0",
              flexShrink: 0,
              paddingLeft: "21px",
              paddingRight: "6px",
              height: tileMode ? 50 : body_common_height,
              backgroundColor: tileMode ? "#e5edf1" : "transparent",
            }}
          >
            {viewMode ? (
              printMode ? (
                <></>
              ) : (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <PageViewStyleSwitcher
                    viewStyle={viewStyle}
                    setViewStyle={setViewStyle}
                  />
                  <div style={{ margin: "10px 20px" }}>
                    <Tooltip title="印刷用ページを開く">
                      <PdfButton
                        onClick={onPrint}
                        style={{ verticalAlign: "middle" }}
                      >
                        <PrinterIcon />
                      </PdfButton>
                    </Tooltip>
                  </div>
                </div>
              )
            ) : (
              <PageEditButtons
                onCreate={onCreate}
                onUndo={undo}
                onRedo={redo}
                onPrint={onPrint}
                onRefresh={onRefresh}
                undoDisabled={commandHistory.length === 0}
                redoDisabled={redoCommandHistory.length === 0}
              />
            )}

            {tileMode ? (
              <></>
            ) : (
              <PageList
                onCreate={onCreate}
                onCopy={onCopy}
                onCut={onCut}
                onPaste={onPaste}
                onDuplicate={onDuplicate}
                onDelete={onDelete}
                onMoveToTop={onMoveToTop}
                onMoveToBottom={onMoveToBottom}
                pageMovingStatus={pageMovingStatus}
                selectedPage={selectedPage}
                selectedPages={selectedPages}
                pageProps={pageProps}
                pageChangeHandler={pageChangeHandler}
                sortPagePropsHandler={sortPagePropsHandler}
                multiplePageChangeHandler={multiplePageChangeHandler}
                setPageMovingStatus={setPageMovingStatus}
                dragPageId={dragPageId}
                setDragPageId={setDragPageId}
                bundleStatus={bundleStatus}
                setBundleStatus={setBundleStatus}
                pageMoving={pageMoving}
                setPageMoving={setPageMoving}
                wsProperties={wsProperties}
                portfolios={portfolios}
                // factorProps={factorProps}
                amData={amData}
                workspaceId={workspaceId}
                tocIndex={tocIndex}
                pageIdIndexMap={pageIdIndexMap}
                productProps={productProps}
                productIdProps={productIdProps}
                assetNames={assetNames}
                assetTypes={assetTypes}
                updateDate={updateDate}
              />
            )}
          </div>
          <div
            className="right-side-area"
            // このdivが、右側のエリア全体
            style={{ width: "100%", height: common_height }}
          >
            {viewMode && viewStyle === "tile" ? (
              <PageTileViewer
                pageProps={pageProps}
                wsProperties={wsProperties}
                portfolios={portfolios}
                amData={amData}
                workspaceId={workspaceId}
                tocIndex={tocIndex}
                pageIdIndexMap={pageIdIndexMap}
                printMode={printMode}
                productProps={productProps}
                productIdProps={productIdProps}
                assetNames={assetNames}
                assetTypes={assetTypes}
                updateDate={updateDate}
              />
            ) : (
              <PageEditor
                params={{ ...params, wsProperties }}
                changeHandler={changeHandler}
                templateId={templateId}
                renderer={renderer}
                chartTypeResizeMap={chartTypeResizeMap}
                selectedLayout={templateId}
                onLayoutChange={changeTemplateId}
                portfolios={portfolios}
                amData={amData}
                workspaceId={workspaceId}
                chartDefinitions={chartDefinitions}
                tocIndex={tocIndex}
                pageIdIndexMap={pageIdIndexMap}
                pageIndex={pageIdIndexMap[selectedPage]}
                productProps={productProps}
                productIdProps={productIdProps}
                assetNames={assetNames}
                assetTypes={assetTypes}
                updateDate={updateDate}
              />
            )}
          </div>
        </div>
      </WSFrame>
    </PageEditorContext.Provider>
  );
};

export default PageEditorSample;
export { PageEditorContext };
/* DnD Implementation */

/**
 * @description マウスポインターが要素と被っているか判定します
 */
const isHover = (event, element) => {
  // マウスポインターの座標を取得
  const clientY = event.clientY;

  // 重なりを判定する要素のサイズと座標を取得
  const rect = element.getBoundingClientRect();

  // マウスポインターが要素と重なっているかを判定する
  // X軸方向には移動させないのでY軸方向のみ判定
  const upperPos = rect.top + rect.height / 5;
  const lowerPos = rect.top + (rect.height * 4) / 5;
  return upperPos < clientY && clientY < lowerPos;
};

const PrintButton = styled.button`
  padding: 8px 15px;
  margin-left: 10px;
  /* width: 140px; */
  height: 30px;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  ${NotosansjpMediumWhite12px}
  font-size: 14px;
  font-weight: 400;
  background-color: #2397ce;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }
  &.inactive {
    background-color: #9fa0a0;
    cursor: initial;
  }
`;

const useDnDSort = (props) => {
  const {
    defaultItems,
    sortPagePropsHandler,
    pageChangeHandler,
    multiplePageChangeHandler,
    pageMovingStatus,
    setPageMovingStatus,
    setDragPageId,
    bundleStatus,
    setBundleStatus,
    pageMoving,
    setPageMoving,
  } = props;
  const { viewMode } = useContext(PageEditorContext);
  const initialSelectedPages = props?.selectedPages;
  // const [items, setItems] = useState(defaultItems);
  const items = defaultItems;

  const MAX_CLICK_DURATION_MS = 500;

  // 状態をrefで管理する
  const state = useRef({
    dndItems: [],
    keys: new Map(),
    dragElement: null,
    movingElements: [],
    canCheckHovered: true,
    lastMouseDownDateTime: null,
    selectedPages: initialSelectedPages,
    previousMouseY: null,
    finishingMove: false,
    bundled: false,
  }).current;

  const oldDndItems = state.dndItems;

  state.dndItems = [];
  items.forEach((item) => {
    const dItem = {
      key: item.pageId,
      value: item,
      element: null,
      position: { x: 0, y: 0 },
    };
    if (oldDndItems.length > 0) {
      const oldItem = oldDndItems.find(({ key }) => key === dItem.key);
      if (oldItem) {
        dItem.element = oldItem.element;
        dItem.position = oldItem.position;
      }
    }
    state.dndItems.push(dItem);
  });

  const removeItem = (dndItems, item) => {
    const index = dndItems.findIndex((dndItem) => dndItem.key === item.key);
    if (index >= 0) {
      dndItems.splice(index, 1);
    }
  };
  const removeItems = (dndItems, items) => {
    items.forEach((item) => {
      removeItem(dndItems, item);
    });
  };

  const moveItemsRegular = (dndItems, hoveredIndex, dragIndex) => {
    const targetItem = dndItems[hoveredIndex];
    const targetIndexBefore = dndItems.findIndex(
      ({ key }) => key === targetItem.key
    );
    const movingItems = dndItems.filter((item) =>
      state.selectedPages.includes(item.key)
    );

    removeItems(dndItems, movingItems);
    const targetIndex =
      dndItems.findIndex(({ key }) => key === targetItem.key) +
      (dragIndex < targetIndexBefore ? 1 : 0);
    dndItems.splice(targetIndex, 0, ...movingItems);
  };

  const moveItemsAround = (dndItems, dragIndex, dragElement) => {
    const movingItems = dndItems.filter((item) =>
      state.selectedPages.includes(item.key)
    );
    removeItems(dndItems, movingItems);
    const dragIndexAfterRemove = Math.min(dragIndex, dndItems.length);
    dndItems.splice(dragIndexAfterRemove, 0, ...movingItems);

    state.justMouseDown = false;
    setBundleStatus("BUNDLING");
    setTimeout(() => {
      state.bundled = true;
    }, 500);
  };

  const moveItems = (dndItems, hoveredIndex, dragElement) => {
    const dragIndex = dndItems.findIndex(({ key }) => key === dragElement.key);
    if (dragIndex === hoveredIndex) {
      moveItemsAround(dndItems, dragIndex, dragElement);
    } else {
      moveItemsRegular(dndItems, hoveredIndex, dragIndex);
    }
  };

  const updateMovingElements = (dragElement, dndItems) => {
    const movingItems = dndItems.filter((item) =>
      state.selectedPages.includes(item.key)
    );
    const newMovingElements = movingItems.map((item) => {
      const element = document.getElementById(item.key);
      return element;
    });
    state.movingElements = newMovingElements;
    return newMovingElements;
  };

  const arrangeItems = (dndItems, dragIndex, dragElement) => {
    const tmpFilteredItems = dndItems.filter(
      (item) =>
        !state.selectedPages.includes(item.key) && item.key !== dragElement.key
    );
    tmpFilteredItems.splice(dragIndex, 0, { element: null });
    tmpFilteredItems.forEach((item, i) => {
      if (item.element === null) return;
      item.element.style.top = 166 * i + "px";
    });
  };

  // ドラッグ中の処理
  const onMouseMove = (event) => {
    if (viewMode) return;
    const { clientY } = event;
    const { dndItems, dragElement } = state;

    // ドラッグして無ければ何もしない
    if (!dragElement) return;
    setPageMoving(true);
    // ドラッグしている要素の配列の位置を取得
    const dragIndex = dndItems.findIndex(({ key }) => key === dragElement.key);

    // マウスポインターの移動量を計算
    const mouseDiff =
      state.previousMouseY !== null && state.previousMouseY !== undefined
        ? clientY - state.previousMouseY
        : 0;
    state.previousMouseY = clientY;
    if (!("cumulativeMouseY" in state)) state.cumulativeMouseY = 0;
    state.cumulativeMouseY += mouseDiff;

    if (!("firstDragIndex" in state)) {
      state.firstDragIndex = dragIndex;
    }
    updateMovingElements(dragElement, dndItems);
    // if (bundleStatus !== "BUNDLING") {
    if (state.bundled) {
      state.movingElements.forEach((element, i) => {
        element.style.zIndex = 90;
        element.style.top =
          state.firstDragIndex * 166 + state.cumulativeMouseY + i * 1 + "px";
        element.style.transform = `rotate(${
          (i * 8) / state.movingElements.length
        }deg)`;
        element.style.transformOrigin = "center";
      });
    }
    const dragStyle = dragElement.element.style;
    dragStyle.zIndex = 100;
    dragStyle.cursor = "grabbing";

    // まだ確認できない場合は処理を終了する => つまり移動直後すぐには移動時の処理をしないように制御している
    if (!state.canCheckHovered) return;

    // 確認できないようにする
    state.canCheckHovered = false;

    // 120ms後に確認できるようにする
    setTimeout(() => (state.canCheckHovered = true), 120);

    // ホバーされている要素の配列の位置を取得
    const hoveredIndex = dndItems.findIndex(
      ({ element, key }, index) =>
        !state.selectedPages.includes(key) && isHover(event, element)
    );

    const moveTargetIndex =
      hoveredIndex >= 0 ? hoveredIndex : state.justMouseDown ? dragIndex : -1;

    // ホバーされている要素があれば、ドラッグしている要素と入れ替える
    if (moveTargetIndex >= 0) {
      moveItems(dndItems, moveTargetIndex, dragElement);

      // ドラッグ要素の座標を更新
      dragElement.element.style.transform = "";

      // 再描画する
      const notSelected = dndItems.filter(
        ({ key }) => !state.selectedPages.includes(key)
      );
      const dragIndex = dndItems.findIndex(({ key }) =>
        state.selectedPages.includes(key)
      );
      notSelected.splice(dragIndex, 0, { element: null });
      notSelected.forEach((item, i) => {
        if (item.element === null) return;
        item.element.style.top = 166 * i + "px";
      });
      // if (bundleStatus === "BUNDLING") {
      //   setBundleStatus("BUNDLED");
      // }
    }
  };

  // ドラッグが終了した時の処理
  const onMouseUp = (event) => {
    state.justMouseDown = false;
    delete state.previousMouseY;
    delete state.firstDragIndex;
    delete state.cumulativeMouseY;
    setPageMovingStatus(false);
    setPageMoving(false);
    state.bundled = false;

    const { dragElement } = state;

    // ドラッグしていなかったら何もしない
    if (!dragElement) return;
    setBundleStatus("STAY");

    state.dndItems.forEach(({ element }, index) => {
      element.style.zIndex = "";
      const currentY = parseInt(element.style.top);
      const targetY = 166 * index;
      const diffY = targetY - currentY;
      element.style.cursor = "pointer";
      element.style.top = `${index * 166}px`;
      element.style.transform = `translate3d(0, ${-diffY}px, 0)`;
      element.style.transition = "all 300ms";
      requestAnimationFrame(() => {
        element.style.transform = "";
      });
    });
    state.finishingMove = true;
    setTimeout(() => {
      state.finishingMove = false;
    }, 400);
    setDragPageId(null);
    const newItems = state.dndItems.map((v) => v.value);
    sortPagePropsHandler(newItems);

    // ドラッグしている要素をstateから削除
    state.dragElement = null;
    state.movingElements = [];

    // windowに登録していたイベントを削除
    window.removeEventListener("mouseup", onMouseUp);
    window.removeEventListener("mousemove", state.onMouseMove);
  };

  return items.map((value) => {
    const key = state.keys.get(value) || value.pageId;
    state.keys.set(value, key);

    return {
      value,

      key,

      events: {
        ref: (element) => {
          if (!element) return;

          const { dndItems, dragElement } = state;

          // 位置をリセットする
          // element.style.transform = "";

          // 要素の位置を取得
          const { top: y } = element.getBoundingClientRect();
          const position = { y };

          const itemIndex = dndItems.findIndex((item) => item.key === key);
          if (itemIndex >= 0) {
            // 既に要素がある場合は位置を更新する
            dndItems[itemIndex].element = element;
            dndItems[itemIndex].position = position;
          } else {
            // 要素が無ければ新しく追加して処理を終わる
            // こんなことはあってほしくないのだが。。。
            console.log("waraning!", key);
            return dndItems.push({ key, value, element, position });
          }

          if (state.selectedPages.includes(key)) {
            if (
              state.dragElement !== null &&
              !state.finishingMove &&
              bundleStatus !== "BUNDLING3"
            ) {
              element.style.transition = "";
            }
          } else {
            // ドラッグ要素以外の要素をアニメーションさせながら移動させる
            const item = dndItems[itemIndex];
            // 前回の座標を計算
            const y = item.position.y - position.y;
            // 要素を前回の位置に留めておく
            element.style.transition = "";
            element.style.transform = `translate(0,${(y * 2) / 5}px)`;
            // 一フレーム後に要素をアニメーションさせながら元に位置に戻す
            requestAnimationFrame(() => {
              element.style.transform = "";
              element.style.transition = "all 80ms";
            });
          }

          // 要素を更新する
          state.dndItems[itemIndex] = { key, value, element, position };
        },

        onMouseDown: (event) => {
          const { dndItems } = state;
          if (!viewMode) setPageMovingStatus(true);

          // try to keep consistency with the state here and the parent's one
          const toBeDeleted = dndItems.filter(
            ({ key }) => items.map(({ pageId }) => pageId).indexOf(key) < 0
          );
          toBeDeleted.forEach(({ key }) => {
            const index = dndItems.findIndex((item) => item.key === key);
            dndItems.splice(index, 1);
          });
          const newItems = dndItems.map((v) => v.value);
          sortPagePropsHandler(newItems);

          if (state.selectedPages.indexOf(value.pageId) < 0 && event.shiftKey) {
            const clickedPageId = value.pageId;
            const clickedPageIndex = dndItems.findIndex(
              ({ key }) => key === clickedPageId
            );
            const mainSelectedPageId =
              state.selectedPages[state.selectedPages.length - 1];
            const mainSelectedPageIndex = dndItems.findIndex(
              ({ key }) => key === mainSelectedPageId
            );
            const additionalSelectPageItems =
              clickedPageIndex < mainSelectedPageIndex
                ? dndItems.slice(clickedPageIndex, mainSelectedPageIndex + 1)
                : dndItems.slice(mainSelectedPageIndex, clickedPageIndex + 1);
            const additionalSelectPageIds = additionalSelectPageItems.map(
              ({ key }) => key
            );
            const selectedPagesIdsToBeAdded = additionalSelectPageIds.filter(
              (pageId) => !state.selectedPages.includes(pageId)
            );
            const selectedPagesIdsToBeAddedSorted =
              mainSelectedPageIndex < clickedPageIndex
                ? selectedPagesIdsToBeAdded
                : selectedPagesIdsToBeAdded.reverse();
            const newSelectedPages = [
              ...state.selectedPages,
              ...selectedPagesIdsToBeAddedSorted,
            ];

            multiplePageChangeHandler(clickedPageId, newSelectedPages);
            state.selectedPages = newSelectedPages;
          } else if (
            !state.selectedPages.includes(value.pageId) ||
            event.metaKey
          ) {
            const newSelectedPages = pageChangeHandler(
              value.pageId,
              event.metaKey
            );
            state.selectedPages = newSelectedPages;
          }
          if (!event.metaKey && !event.shiftKey) {
            state.justMouseDown = true;
            setTimeout(() => {
              if (state.dragElement === null) {
                state.justMouseDown = false;
                setBundleStatus("STAY");

                if (state.selectedPages.length > 1) {
                  // Somehow, the client event won't be fired when the multiple pages are selected
                  // So, here we handle the case when the user clicks on one of the selected pages
                  const newSelectedPages = pageChangeHandler(
                    value.pageId,
                    false
                  );
                  state.selectedPages = newSelectedPages;
                }
              } else {
                // TODO: ほよほよmoveスタート
                setBundleStatus("BUBBLING");
              }
            }, 500);
          }

          // ドラッグする要素(DOM)
          const element = event.currentTarget;

          // ドラッグしている要素のスタイルを上書き
          element.style.transition = ""; // アニメーションを無効にする
          element.style.cursor = "grabbing"; // カーソルのデザインを変更
          element.style.zIndex = 100; // 要素を最前面に移動

          // 要素の座標を取得
          const { left: x, top: y } = element.getBoundingClientRect();
          const position = { x, y };
          if (viewMode) return;

          // ドラッグする要素を保持しておく
          state.dragElement = {
            key,
            value,
            element,
            position,
            basePosition: position,
            baseDiff: event.clientY - position.y,
          };

          setDragPageId(key);

          // mousemove, mouseupイベントをwindowに登録する
          window.addEventListener("mouseup", onMouseUp);
          window.addEventListener("mousemove", onMouseMove);
          state.lastMouseDownDateTime = new Date();
        },

        onClick: (event) => {
          if (!event.metaKey && !event.shiftKey) {
            if (
              !state.lastMouseDownDateTime ||
              new Date() - state.lastMouseDownDateTime < MAX_CLICK_DURATION_MS
            ) {
              setPageMovingStatus(false);
              setBundleStatus("STAY");
              pageChangeHandler(value.pageId, event.metaKey);
              state.selectedPages = [value.pageId];
              state.lastMouseDownDateTime = null;
              state.justMouseDown = false;
            }
          }
        },
      },
    };
  });
};
