import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  createPAM,
  getAssetNamingByCustomerId,
  getPAMListByCustomerId,
  updatePAM,
} from "api/Customer";
import {
  getAssetTypes,
  getBenchmarksForPAMByCustomerId,
  listAssetDefaultBMMapping,
} from "api/ProductMaster";
import { getWorkspacePropertiesListByCustomerId } from "api/Workspace";
import { BigNumber } from "bignumber.js";
import PAMDeletionDialog from "components/PAMDeleter";
import WSDeletionDialog from "components/WSDeleter";
import { AMItem } from "components/WSProperties";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { matchDecimalNumber } from "utils/NumberUtils";
import { sortAssetsByAssetTypeStandardOrder } from "utils/PortfolioUtils";
import { TrashIcon } from "utils/SVGIcons";
import { formatDate } from "utils/StringUtils";
import {
  NotosansjpMediumCloudBurst12px,
  NotosansjpMediumWhite12px,
  NotosansjpNormalNavy10px,
  NotosansjpNormalNavy13px,
} from "../../styledMixins";

import BenchmarkEditDialog from "components/BenchmarkEditDialog";

import { assetColors } from "components/AssetColorMap";

const CustomerAMList = (props) => {
  const {
    ams,
    selectedAm,
    assetNames,
    setSelectedAm,
    setPamToEdit,
    editMode,
    onClickAddAM,
    extensible,
    reload,
    assetTypes,
  } = props;

  const [pamToDelete, setPamToDelete] = useState(null);
  const checkBoxes = [
    { label: "名前昇順", value: "alphabetical-ascend" },
    { label: "名前降順", value: "alphabetical-descend" },
    { label: "適用開始日昇順", value: "application-date-ascend" },
    { label: "適用開始日降順", value: "application-date-descend" },
  ];
  const [sortBy, setSortBy] = useState("alphabetical-ascend");

  const sort = (list) => {
    if (!list) return list;
    switch (sortBy) {
      case "alphabetical-ascend":
        return list.sort((a, b) => (a?.name || "").localeCompare(b.name));
      case "alphabetical-descend":
        return list.sort((a, b) => (b?.name || "").localeCompare(a.name));
      case "application-date-ascend":
        return list.sort((a, b) => a.baseDate.localeCompare(b.baseDate));
      case "application-date-descend":
        return list.sort((a, b) => b.baseDate.localeCompare(a.baseDate));
      default:
        return list;
    }
  };

  const generateRadioButtonChangeHandler = (value) => {
    return (e) => {
      e.preventDefault();
      setSortBy(value);
    };
  };
  const [notReselectedAfterAttention, setNotReselectedAfterAttention] =
    useState(true);

  const generateSelectItemHandler = (item) => {
    return (e) => {
      e.preventDefault();
      setNotReselectedAfterAttention(false);
      if (item.id === selectedAm?.id) {
        setSelectedAm(null);
      } else {
        setSelectedAm(item);
      }
    };
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PAMDeletionDialog
        am={pamToDelete}
        onCancel={() => setPamToDelete(null)}
        processAfterDeletion={reload}
      />

      <SearchOptionButtons>
        {checkBoxes.map((checkBox, i) => (
          <div key={checkBox.value}>
            <RadioButton
              name="sort-order"
              id={checkBox.value}
              value={checkBox.value}
              type="radio"
              checked={sortBy === checkBox.value}
              onChange={generateRadioButtonChangeHandler(checkBox.value)}
            />
            <RadioButtonLabel
              htmlFor={checkBox.value}
              key={checkBox.value}
              className={checkBox.value === sortBy ? "checked" : ""}
              onClick={generateRadioButtonChangeHandler(checkBox.value)}
            >
              {checkBox.label}
            </RadioButtonLabel>
          </div>
        ))}
      </SearchOptionButtons>
      <div className="spacer" style={{ height: "20px" }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          fontSize: 12,
          alignItems: "center",
          gap: 10,
          padding: "0 3px 0 5px",
          height: 30,
          borderBottom: "1px solid #dddcdb",
          marginBottom: 10,
        }}
      >
        <div style={{ flexGrow: 1 }}>政策AM名称</div>
        <div style={{ marginRight: 30 }}>資産比率</div>
        <div style={{ width: 62 }}>適用開始日</div>
        <div style={{ width: 24 }}>編集</div>
        <div style={{ width: 24 }}>削除</div>
        {/* <div style={{ width: 24 }}>複製</div> */}
        {extensible && <div style={{ width: 12 }} />}
      </div>
      <ItemList
        className="master-items"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          // maxHeight: `calc(100vh - ${editMode ? 380 : 280}px)`,
        }}
      >
        {sort(ams)?.map((am, i) => (
          <AMItem
            key={`master-product-${i}`}
            selected={selectedAm?.id === am.id}
            onClick={generateSelectItemHandler(am)}
            assetNaming={assetNames}
            am={am}
            extensible={extensible}
            deletable={true}
            reload={reload}
            setPamToDelete={setPamToDelete}
            setPamToEdit={setPamToEdit}
            assetTypes={assetTypes}
          />
        ))}
      </ItemList>
      <div className="spacer" style={{ height: "50px" }} />
      {!editMode && (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <OpenButton onClick={onClickAddAM}>
            <img src="/img/create_file_icon.svg" />
            <div>政策AM新規作成</div>
          </OpenButton>
        </div>
      )}
    </div>
  );
};

const ReEditButton = styled.button`
  padding: 8px 0;
  margin-left: 10px;
  width: 100px;
  height: 36px;
  ${NotosansjpMediumWhite12px}
  color: #2397ce;

  background-color: transparent;
  border: 1px solid #dddcdb;
  &:not(.inactive):hover {
    background-color: #e5eced;
    cursor: pointer;
  }
  &:not(.inactive):active {
    background-color: #192e55;
  }
  &.inactive {
    color: #9fa0a0;
  }
`;
const OpenButton = styled.button`
  padding: 8px 15px;
  margin-left: 10px;
  /* width: 140px; */
  height: 30px;
  border-radius: 15px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  ${NotosansjpMediumWhite12px}
  font-size: 11px;
  font-weight: 500;
  background-color: #2397ce;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }
  &.inactive {
    background-color: #9fa0a0;
    cursor: initial;
  }
`;

const SaveButton = styled.button`
  padding: 8px 15px;
  height: 36px;
  color: #ffffff;

  ${NotosansjpMediumWhite12px}
  font-size: 11px;
  font-weight: 500;
  background-color: #2397ce;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #2b7eb0;
  }
  &:active {
    background-color: #192e55;
  }
  &.inactive {
    background-color: #9fa0a0;
    cursor: initial;
  }
`;

const getDataForAMEditor = async (customerId) => {
  const assetNames = await getAssetNamingByCustomerId(customerId);
  const productCandidates = await getBenchmarksForPAMByCustomerId(customerId);
  const assetDefaultBMMapping = await listAssetDefaultBMMapping(customerId);

  return { productCandidates, assetNames, assetDefaultBMMapping };
};

const NameInput = styled.input`
  &.invalid {
    border: 1px solid #ff0000;
  }
`;

const DateInput = styled.input`
  &.invalid {
    border: 1px solid #ff0000;
  }
`;

const TextAreaFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  border: 1px solid #1fd7ff;
  flex-grow: 1;
  &.inactive {
    background: transparent;
    border: 1px solid #dddcdb;
  }
  &.empty {
    background-color: #ffffff;
  }
  /* &.attention {
    border: 1px solid #fc8661;
  } */
  &.invalid {
    background-color: #f2c5c2;
    border: 1px solid #e94a3f;
  }
  &.valid {
    background-color: #d2f2ff;
  }
  /* .completed & {
    background-color: transparent;
    border: none;
    border: 1px solid transparent;
    border-bottom: 1px solid #1fd7ff;
  } */

  & textarea {
    ${NotosansjpMediumCloudBurst12px}
    font-weight: 400;
    font-size: 14px;
    width: 15em;
    resize: none;
    overflow: hidden;
    border: none;
    margin-left: 8px;
    outline: none;
    padding: 0;
    background-color: transparent;
  }
  & textarea::placeholder {
    color: #9fa0a0;
  }
  & input {
    color: #192e55;
    width: 250px;
    border: none;
    margin-left: 8px;
    font-size: 14px;
    outline: none;
    background: transparent;
  }
  & input::placeholder {
    color: #9fa0a0;
  }
`;

const PenIcon = styled.div`
  background-image: url(/img/penIconInactive.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 15px;
  height: 15px;
  margin: 0 15px;
  .active & {
    background-image: url(/img/penIconActive.svg);
  }
  .invalid & {
    background-image: url(/img/x-circle.svg);
  }
  .attention & {
    background-image: url(/img/alert-triangle.svg);
  }
  .none &,
  .completed & {
    background: none;
  }

  .valid & {
    background-image: url(/img/valid_circle_check.svg);
  }
  .number & {
    background-image: none;
  }
  .number .valid & {
    background-image: url(/img/valid_circle_check.svg);
  }
  .number.total .invalid & {
    background-image: url(/img/x-circle.svg);
  }
`;

const TextArea = forwardRef((props, ref) => {
  const {
    value = "",
    setValue,
    placeholder,
    active = true,
    valid,
    numLines,
    invalidReason,
  } = props;
  const [focused, setFocused] = useState(false);
  const className = active
    ? valid
      ? focused
        ? "active"
        : "valid"
      : value === ""
      ? "empty"
      : "invalid"
    : "inactive";

  const onChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const focusHandler = (e) => {
    setFocused(true);
  };
  const blurHandler = (e) => {
    setFocused(false);
  };
  const completed = !active && value !== "";
  const frameStyle =
    numLines === 1 && completed ? { height: 23, marginBottom: 24 } : {};
  const areaStyle =
    numLines === 1 && completed ? { height: 23, marginBottom: 8 } : {};
  return (
    <div>
      <TextAreaFrame className={className} style={frameStyle}>
        <textarea
          ref={ref}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          disabled={!active}
          onFocus={focusHandler}
          onBlur={blurHandler}
          style={areaStyle}
        />
        <PenIcon />
      </TextAreaFrame>
      {!valid && invalidReason && (
        <div style={{ color: "#FC8661" }}>{invalidReason}</div>
      )}
    </div>
  );
});

const TextField = (props) => {
  const {
    value = "",
    setValue,
    placeholder,
    active = true,
    valid,
    type,
    iconPosition = "right",
    style = {},
    inputStyle = {},
    iconStyle = {},
    invalidReasonStyle = {},
    additionalText = "",
    classNameWhenNotValid = null,
    readOnlyMode = false,
    invalidReason,
    adjust = false,
  } = props;

  const [focused, setFocused] = useState(false);
  const classNameRaw = active
    ? valid
      ? focused
        ? "active"
        : "valid"
      : value === ""
      ? "empty"
      : "invalid"
    : "inactive";
  const className = valid
    ? classNameRaw
    : classNameWhenNotValid
    ? classNameWhenNotValid
    : classNameRaw;

  const focusHandler = (e) => {
    setFocused(true);
  };
  const blurHandler = (e) => {
    setFocused(false);
  };

  const onChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const rootAdjust = adjust ? { position: "relative" } : {};
  const frameAdjust = adjust
    ? { position: "absolute", top: -21, ...style }
    : style;

  return (
    <div style={rootAdjust}>
      <TextAreaFrame className={className} style={frameAdjust}>
        {iconPosition === "left" && <PenIcon style={iconStyle} />}
        {readOnlyMode ? (
          <div style={inputStyle}>{value}</div>
        ) : (
          <input
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={!active}
            onFocus={focusHandler}
            onBlur={blurHandler}
            type={type}
            style={inputStyle}
          />
        )}
        {additionalText ? (
          <div style={{ padding: 4, fontSize: 8 }}>{additionalText}</div>
        ) : (
          ""
        )}
        {iconPosition === "right" && <PenIcon />}
      </TextAreaFrame>
      {!valid && invalidReason && (
        <div style={{ ...invalidReasonStyle, color: "#FC8661" }}>
          {invalidReason}
        </div>
      )}
    </div>
  );
};

export { OpenButton, TextField };

const Tester = styled.div`
  ${NotosansjpMediumCloudBurst12px}
  font-size: 15px;
  width: 15em;
  overflow: hidden;
  border: none;
  margin-left: 8px;
  outline: none;
  padding: 0;
  visibility: hidden;
  position: absolute;
`;

const convertDate = (dateS) => {
  const ymd = dateS.split("/");
  return `${ymd[0]}-${ymd[1]}-${ymd[2]}`;
};

const convertBenchmark = (origBenchmark, bmMap) => {
  return Object.entries(origBenchmark).map(([productName, origAssign]) => {
    const product = bmMap[productName];
    const assign = new BigNumber(origAssign).multipliedBy(100).toNumber();
    return { ...product, assign, assignText: String(assign) };
  });
};

const generateInitialAm = (pamToEdit, productCandidates) => {
  const bmMap = {};
  productCandidates.forEach((products, assetType) => {
    products.forEach((p) => {
      bmMap[p.product_name] = p;
    });
  });
  const initialAm = new Map();
  const initialAmName = pamToEdit?.name;
  const initialAmStartDate = convertDate(pamToEdit?.baseDate);
  const initialBenchmarkSet = {};
  pamToEdit?.assetAssigns.forEach((assign, assetType) => {
    const origBenchmark = pamToEdit?.assetBMs.get(assetType);
    const benchmark = convertBenchmark(origBenchmark, bmMap);
    initialAm.set(assetType, { assign, benchmark, assignText: String(assign) });
    initialBenchmarkSet[assetType] = benchmark;
  });
  return { initialAm, initialAmName, initialAmStartDate, initialBenchmarkSet };
};

const CustomerAMEditor = (props) => {
  const { ams, customerId, addAm, cancel, pamToEdit } = props;

  const [assetDefaultBMMapping, setAssetDefaultBMMapping] = useState(null);
  const [productCandidates, setProductCandidates] = useState(new Map());
  const [benchmarksSet, setBenchmarksSet] = useState({});
  const [assetNames, setAssetNames] = useState(new Map());
  const [openDialog, setOpenDialog] = useState(false);
  const [am, setAm] = useState(new Map());
  const [amName, setAmName] = useState(pamToEdit ? pamToEdit?.name : "");
  const [amNameClassName, setAmNameClassName] = useState("invalid");
  const today = new Date();
  const [amStartDate, setAmStartDate] = useState(
    pamToEdit
      ? convertDate(pamToEdit.baseDate)
      : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
  );
  const [amStartDateClassName, setAmStartDateClassName] = useState("invalid");

  const testerRef = useRef(null);
  const textAreaRef = useRef(null);

  const [numLines, setNumLines] = useState(1);
  const [assetToSetDefaultBM, setAssetToSetDefaultBM] = useState(null);
  const state = useRef({ cursorPos: null });

  useEffect(() => {
    if (!pamToEdit || !productCandidates) return;
    const {
      initialAm,
      initialAmName,
      initialAmStartDate,
      initialBenchmarkSet,
    } = generateInitialAm(pamToEdit, productCandidates);
    setAm(initialAm);
    setAmName(initialAmName);
    setAmStartDate(initialAmStartDate);
    setBenchmarksSet(initialBenchmarkSet);
  }, [pamToEdit, productCandidates]);

  const adjustText = () => {
    if (!textAreaRef.current || !testerRef.current) {
      return;
    }
    const value = textAreaRef.current.value;
    const lines = value.split("\n");
    const newLines = lines.length > 2 ? lines.slice(0, 2) : lines;
    const newValue = newLines.join("\n");
    testerRef.current.innerText = newValue;
    const height = testerRef.current.clientHeight;
    const newNumLines = height > 30 ? 2 : 1;
    setNumLines(newNumLines);

    const tooTall = height >= 68;
    const refinedText = tooTall
      ? newValue.substring(0, newValue.length - 1)
      : newValue;
    const newCursorPos = textAreaRef.current.selectionStart;

    if (tooTall) {
      state.cursorPos = newCursorPos;
      setTimeout(adjustText, 10);
    } else {
      if (state.cursorPos !== null && state.cursorPos !== undefined) {
        textAreaRef.current.selectionStart = state.cursorPos - 1;
        textAreaRef.current.selectionEnd = state.cursorPos - 1;
        state.cursorPos = null;
      }
      setAmName(refinedText);
    }
  };

  const setAmNameAndAdjust = (newAmName) => {
    adjustText();
  };

  const convertBenchmark = (benchmark) => {
    const newBenchmark = {};
    benchmark?.forEach((bm) => {
      newBenchmark[bm.product_name] = new BigNumber(bm.assign)
        .div(100)
        .toNumber();
    });
    return newBenchmark;
  };
  const convertBenchmarkWithId = (benchmark) => {
    const newBenchmark = {};
    benchmark?.forEach((bm) => {
      newBenchmark[bm.product_id] = new BigNumber(bm.assign)
        .div(100)
        .toNumber();
    });
    return newBenchmark;
  };

  const generateAm = () => {
    const assetAssigns = new Map();
    for (const [asset, assetInfo] of am) {
      if (!(assetInfo.assign >= 0)) continue;
      assetAssigns.set(asset, assetInfo.assign);
    }

    const assetBMs = new Map();
    const assetBMIds = new Map();
    for (const [asset, assetInfo] of am) {
      assetBMs.set(asset, convertBenchmark(assetInfo.benchmark));
      assetBMIds.set(asset, convertBenchmarkWithId(assetInfo.benchmark));
    }
    return {
      id: amName,
      name: amName,
      baseDate: amStartDate,
      assetAssigns,
      assetBMs,
      assetBMIds,
    };
  };

  const clickHandler = (e) => {
    e.preventDefault();
    const am = generateAm();
    if (pamToEdit) {
      updatePAM(pamToEdit.id, am)
        .then((res) => {
          const pamId = res;
          am.id = pamId;
          addAm(am);
        })
        .catch((err) => {
          console.log("updatePAM error: ", err);
        });
    } else {
      createPAM(customerId, am)
        .then((res) => {
          const pamId = res;
          am.id = pamId;
          addAm(am);
        })
        .catch((err) => {
          console.log("createPAM error: ", err);
        });
    }
  };

  const cancelClickHandler = (e) => {
    e.preventDefault();
    cancel();
  };

  const onNameChange = (e) => {
    e.preventDefault();
    setAmName(e.target.value);
  };

  useEffect(() => {
    const duplicated = ams
      .filter(({ id }) => id !== pamToEdit?.id)
      .map(({ name }) => name.replace(/\s/, ""))
      .includes(amName.replace(/\s/, ""));
    const newClassName =
      duplicated || amName.length === 0 ? "invalid" : "valid";
    setAmNameClassName(newClassName);
  }, [amName]);

  const onDateChange = (e) => {
    e.preventDefault();
    setAmStartDate(e.target.value);
  };

  const validDate = (dateS) => {
    const m = dateS.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    if (!m) return false;
    const date = new Date(dateS);
    const valid =
      date.getFullYear() === Number(m[1]) &&
      date.getMonth() + 1 === Number(m[2]) &&
      date.getDate() === Number(m[3]);
    return valid;
  };

  useEffect(() => {
    setAmStartDateClassName(validDate(amStartDate) ? "valid" : "invalid");
  }, [amStartDate]);

  useEffect(() => {
    if (customerId === null || customerId === undefined) return;
    getDataForAMEditor(customerId).then(
      ({ productCandidates, assetNames, assetDefaultBMMapping }) => {
        setProductCandidates(productCandidates);
        const idProductMap = {};
        productCandidates.forEach((products, assetType) => {
          products.forEach((p) => {
            idProductMap[p.product_id] = p;
          });
        });

        setAssetNames(assetNames);
        const newAssetDefaultBMMapping = Object.fromEntries(
          assetDefaultBMMapping.map((m) => [
            m.asset_type,
            {
              bm_id: m.product_id,
              bm: idProductMap[m.product_id],
              ratio: new BigNumber(m.ratio).toNumber(),
            },
          ])
        );

        setAssetDefaultBMMapping(newAssetDefaultBMMapping);
      }
    );
  }, [customerId]);

  useEffect(() => {
    if (assetToSetDefaultBM === null) return;

    const assign = assetDefaultBMMapping[assetToSetDefaultBM]?.ratio * 100;
    const newBenchmarks = [
      {
        ...assetDefaultBMMapping[assetToSetDefaultBM]?.bm,
        assign: assign,
        assignText: String(assign),
      },
    ];
    updateBenchmarks({ assetType: assetToSetDefaultBM, newBenchmarks });

    setAssetToSetDefaultBM(null);
  }, [assetToSetDefaultBM]);

  const generateSetter = (asset) => {
    return (newValue) => {
      const newAm = new Map(am);
      if (!newAm.has(asset)) {
        newAm.set(asset, { benchmark: benchmarksSet[asset] });
      }
      if (newValue === "") {
        newAm.delete(asset);
        setAm(newAm);
      } else {
        const newAssign = new BigNumber(newValue).toNumber();
        if (!matchDecimalNumber(newValue, false)) return;
        newAm.get(asset).assign = newAssign;
        newAm.get(asset).assignText = newValue;
        setAm(newAm);

        if (
          (!newAm.get(asset).benchmark ||
            newAm.get(asset).benchmark.length === 0) &&
          assetDefaultBMMapping[asset]?.bm
        ) {
          setAssetToSetDefaultBM(asset);
        }
      }
    };
  };

  const updateBenchmarks = ({ assetType, newBenchmarks }) => {
    const newBenchmarksSet = Object.assign({}, benchmarksSet);
    newBenchmarksSet[assetType] = newBenchmarks;
    setBenchmarksSet(newBenchmarksSet);

    const newAm = new Map(am);
    if (!newAm.has(assetType)) {
      newAm.set(assetType, {});
    }
    newAm.get(assetType).benchmark = newBenchmarks;
    setAm(newAm);
  };

  const assignedAssets = Array.from(am.entries()).filter(
    ([asset, info]) => info.assign > 0
  );
  const assignedAssetsHaveBenchmarks = assignedAssets.every(
    ([asset, info]) => info.benchmark
  );
  const totalAssignsIs100 =
    assignedAssets
      .reduce(
        (acc, [asset, info]) => acc.plus(new BigNumber(info.assignText)),
        new BigNumber(0)
      )
      .toNumber() === 100;
  const valid =
    assignedAssetsHaveBenchmarks &&
    totalAssignsIs100 &&
    amNameClassName === "valid" &&
    amStartDateClassName === "valid";
  const saveButtonClass = valid ? "" : "inactive";

  const validAsset = (asset) => {
    return am.get(asset)?.assign > 0 && am.get(asset)?.benchmark;
  };

  const totalAssignsTmp = Array.from(am?.values() || []).reduce(
    (sum, a) => sum.plus(new BigNumber(a.assignText || 0)),
    new BigNumber(0)
  );
  const totalAssigns = totalAssignsTmp.toNumber();

  return (
    <div>
      <ListTitle style={{ fontWeight: 400 }}>
        <div style={{ padding: 5 }}>
          {pamToEdit ? `政策AM『${pamToEdit.name}』編集` : "政策AM新規作成"}
        </div>
        <div style={{ borderBottom: "1px solid #dddcdb" }} />
      </ListTitle>

      <div style={{ marginTop: 12 }}>
        <Table>
          <tbody>
            <tr>
              <th style={{ width: 100 }}>政策AM名称</th>
              <td>
                <Tester ref={testerRef} />

                <TextArea
                  ref={textAreaRef}
                  value={amName}
                  setValue={setAmNameAndAdjust}
                  placeholder="未入力"
                  valid={amNameClassName === "valid"}
                  numLines={numLines}
                  invalidReason={
                    amNameClassName !== "valid" && amName
                      ? "過去の政策AMの名称と重複しています。重複のない名称を設定してください。"
                      : null
                  }
                />
              </td>
            </tr>
            <tr>
              <th style={{ width: 100 }}>適用開始日</th>
              <td>
                <TextField
                  type="date"
                  value={amStartDate}
                  setValue={setAmStartDate}
                  valid={amStartDateClassName === "valid"}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Table style={{ marginTop: 32 }}>
          <thead>
            <tr>
              <th style={{ whiteSpace: "nowrap" }}>資産型</th>
              <th style={{ padding: "8px 0", whiteSpace: "nowrap" }}>
                資産配分
              </th>
              <th></th>
              <th style={{ whiteSpace: "nowrap" }}>ベンチマーク</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(Object.entries(assetNames)).map(
              ([origAsset, assetName], i) => (
                <tr
                  key={i}
                  className="number"
                  //  className={validAsset(origAsset) ? "" : "invalid"}
                >
                  <td // 資産型
                    style={{
                      width: 120,
                      padding: "0 8px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 12,
                          alignItems: "center",
                          marginTop: 4,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: assetColors[origAsset],
                            width: 3,
                            height: 20,
                            flexGrow: 0,
                          }}
                        />
                        <div> {assetName}</div>
                      </div>
                    </div>
                  </td>
                  <td // 資産配分
                    style={{
                      width: 65,
                      padding: 0,
                      backgroundColor: "#E4F7FF",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <TextField
                      value={
                        am.get(origAsset)?.assignText ||
                        am.get(origAsset)?.assign
                      }
                      setValue={generateSetter(origAsset)}
                      iconPosition="left"
                      additionalText="%"
                      style={{
                        width: 65,
                        marginBottom: 20,
                        color:
                          am.get(origAsset)?.assignText ||
                          am.get(origAsset)?.assign
                            ? "#192e55"
                            : "#9fa0a0",
                      }}
                      inputStyle={{
                        width: 30,
                        margin: 0,
                        fontSize: 11,
                        textAlign: "right",
                      }}
                      valid={totalAssignsIs100}
                      classNameWhenNotValid="empty"
                      iconStyle={{
                        margin: 0,
                        padding: "0 5px",
                        minWidth: 9,
                        height: 9,
                      }}
                      adjust={true}
                    />
                    {/* </div> */}
                  </td>
                  <td // 資産配分グラフ
                    style={{
                      width: 132,
                      padding: "3px 10px",
                      whiteSpace: "nowrap",
                      verticalAlign: "top",
                    }}
                  >
                    <div style={{ width: 120, paddingTop: 4 }}>
                      <div
                        style={{
                          width: `${
                            (am.get(origAsset)?.assign > 120
                              ? 120
                              : am.get(origAsset)?.assign) || 0
                          }%`,
                          height: 14,
                          backgroundColor: assetColors[origAsset],
                        }}
                      />
                    </div>
                  </td>
                  <td // ベンチマーク
                    style={{
                      minWidth: "200px",
                      padding: "0 16px 8px",
                      whiteSpace: "nowrap",
                      verticalAlign: "bottom",
                    }}
                  >
                    {/* <div style={{ position: "relative" }}> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                        // position: "absolute",
                      }}
                    >
                      <div>
                        <BenchmarkEditDialog
                          assetType={origAsset}
                          products={benchmarksSet[origAsset] || []}
                          productsMaster={productCandidates.get(origAsset)}
                          updateProducts={updateBenchmarks}
                          setOpenDialog={setOpenDialog}
                          attention={
                            am.get(origAsset)?.assign > 0 &&
                            !benchmarksSet[origAsset]
                          }
                        />
                      </div>

                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                          {benchmarksSet[origAsset] &&
                            benchmarksSet[origAsset].map((b, j) => (
                              <div
                                key={`${i}-${j}`}
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div style={{ width: 200 }}>
                                  {b.product_name}
                                </div>
                                <div style={{ width: 50 }}>{b.assign}%</div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </td>
                </tr>
              )
            )}
            <tr className="number total">
              <td style={{ padding: "25px 0 0px" }}>資産配分合計</td>
              <td style={{ width: 65, padding: 0, backgroundColor: "#E4F7FF" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 10,
                    width: 65,
                  }}
                >
                  {/* <div style={{ width: 62 }}>{totalAssigns}%</div> */}
                  <TextField
                    value={totalAssigns}
                    iconPosition="left"
                    additionalText="%"
                    style={{ width: 65 }}
                    inputStyle={{
                      width: 30,
                      margin: 0,
                      fontSize: 11,
                      textAlign: "right",
                    }}
                    valid={totalAssignsIs100}
                    classNameWhenNotValid={
                      totalAssigns > 100 ? "invalid" : "empty"
                    }
                    iconStyle={{
                      margin: 0,
                      padding: "0 5px",
                      minWidth: 9,
                      height: 9,
                    }}
                    readOnlyMode={true}
                  />
                </div>
              </td>
              <td style={{ width: 180, padding: "3px 10px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      width: 120,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        width: `${
                          (totalAssigns > 100 ? 100 : totalAssigns) || 0
                        }%`,
                        height: 14,
                        backgroundColor:
                          totalAssigns > 100
                            ? "#FF4D00"
                            : totalAssigns < 100
                            ? "#A7E0F7"
                            : "#2397CE",
                      }}
                    />
                    {totalAssigns < 100 && (
                      <div
                        style={{
                          width: `${100 - totalAssigns}%`,
                          height: 14,
                          backgroundColor: "#E4F7FF",
                        }}
                      />
                    )}
                  </div>

                  {totalAssigns > 100 && (
                    <div style={{ width: 120 }}>
                      <div
                        style={{
                          width: `${
                            totalAssigns - 100 > 100 ? 100 : totalAssigns - 100
                          }%`,
                          height: 14,
                          backgroundColor: "#F2C5C2",
                        }}
                      />
                    </div>
                  )}
                </div>
              </td>
            </tr>
            {totalAssigns > 100 && (
              <tr>
                <td></td>
                <td
                  colSpan={2}
                  style={{ color: "#FC8661", padding: "5px 2px" }}
                >
                  資産配分合計が100%を超えています。
                  <br /> 合計が100%になるように資産配分を設定してください。
                </td>
                <td></td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 20,
          marginTop: 30,
        }}
      >
        <ReEditButton onClick={cancelClickHandler}>キャンセル</ReEditButton>
        <SaveButton
          onClick={clickHandler}
          className={saveButtonClass}
          disabled={!valid}
        >
          {pamToEdit ? "政策AM更新" : "政策AM新規作成"}
        </SaveButton>
      </div>
    </div>
  );
};

const Table = styled.table`
  width: 100%;
  & th,
  & td {
    font-size: 12px;
    padding: 8px 16px;
    text-align: left;
  }
  & thead th {
    background-color: rgb(238, 250, 255);
  }
  /* & tbody td {
    text-align: left;
  } */
  & tr.invalid {
    background-color: #eeeeee;
  }
`;

const CustomerAMDetail = (props) => {
  const { am, assetNames, wsList } = props;
  const [sortedAssetAssigns, setSortedAssetAssigns] = useState([]);
  useEffect(() => {
    if (!am || !am.assetAssigns) return;

    sortAssetsByAssetTypeStandardOrder(Array.from(am.assetAssigns.keys())).then(
      (sorted) => {
        setSortedAssetAssigns(sorted);
      }
    );
  }, [am]);

  if (!am) return <div>政策AMが選択されていません</div>;

  const assetDetail = (asset, assign, bm, ratio, i, last, assetNames) => {
    const assetName = assetNames.hasOwnProperty(asset)
      ? assetNames[asset]
      : asset;
    if (i === 0) {
      return (
        <tr key={`${asset}-${i}`} style={{ height: 30 }}>
          <td
            style={{
              whiteSpace: "nowrap",
              width: "40px",
              padding: "6px 60px 0 0",
            }}
          >
            {assetName}
          </td>
          <td
            style={{
              whiteSpace: "nowrap",
              width: "20px",
              padding: "6px 10px 0 0 ",
              textAlign: "right",
            }}
          >
            {new BigNumber(assign).toFixed(1)}%
          </td>
          <td
            style={{
              minWidth: 110,
              display: "flex",
              alignItems: "flex-end",
              height: 20,
              whiteSpace: "nowrap",
              paddingRight: 10,
            }}
          >
            <div
              style={{
                width: `${assign}%`,
                height: 8,
                backgroundColor: assetColors[asset],
              }}
            />
          </td>
          <td style={{ width: 200, whiteSpace: "nowrap" }}>{bm}</td>
          <td style={{ textAlign: "right" }}>
            {new BigNumber(ratio).times(100).toFixed(1)}%
          </td>
        </tr>
      );
    } else {
      return (
        <tr key={`${asset}-${i}`} style={{ height: 30 }}>
          <td
            style={{
              whiteSpace: "nowrap",
              width: "40px",
              padding: "6px 60px 0 0",
            }}
          ></td>
          <td
            style={{
              whiteSpace: "nowrap",
              width: "20px",
              padding: "6px 10px 0 0 ",
              textAlign: "right",
            }}
          ></td>
          <td
            style={{
              minWidth: 110,
              display: "flex",
              alignItems: "flex-end",
              height: 20,
              whiteSpace: "nowrap",
            }}
          ></td>
          <td style={{ width: 200, whiteSpace: "nowrap" }}>{bm}</td>
          <td style={{ textAlign: "right" }}>
            {new BigNumber(ratio).times(100).toFixed(1)}%
          </td>
        </tr>
      );
    }
  };

  return (
    <div>
      <ListTitle style={{ fontWeight: 400 }}>
        <div style={{ padding: "5px 12px" }}>政策AM『{am.name}』の詳細</div>
        <div style={{ borderBottom: "1px solid #dddcdb" }} />
      </ListTitle>

      <div
        style={{
          fontFamily: '"Noto Sans JP", Helvetica',
          fontWeight: 500,
          fontSize: 12,
          display: "flex",
          flexDirection: "row",
          gap: 150,
          padding: "20px 24px",
        }}
      >
        {/* <div>
          <PartLabel>名称</PartLabel>
          <div>{am.name}</div>
        </div> */}
        <div>
          <PartLabel>適用開始日</PartLabel>
          <div>{am.baseDate}</div>
        </div>
      </div>

      <div
        style={{
          fontSize: 12,
          padding: "16px 24px",
          display: "flex",
          flexDirection: "column",
          gap: 24,
          backgroundColor: "#EEFAFF",
        }}
      >
        <table>
          <tbody>
            {sortedAssetAssigns.map((asset) => {
              const assign = am.assetAssigns.get(asset);
              const bms = am.assetBMs.get(asset);
              const sortedBmKeys = bms
                ? Object.keys(bms).sort((a, b) => a.localeCompare(b))
                : [];
              const bmArray = sortedBmKeys.map((bm) => [bm, bms[bm]]);

              return bmArray.map(([bm, ratio], i) =>
                assetDetail(
                  asset,
                  assign,
                  bm,
                  ratio,
                  i,
                  i === bmArray.length - 1,
                  assetNames
                )
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PartLabel = styled.div`
  ${NotosansjpNormalNavy13px}
  font-size: 11px;
`;

const IconDiv = (props) => {
  const { url, onClick } = props;
  return (
    <div
      style={{
        backgroundImage: `url(${url})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: 24,
        height: 24,
        cursor: "pointer",
      }}
      onClick={onClick}
    ></div>
  );
};

const WorkSpaceList = (props) => {
  const { wsList, am, customerId, reload } = props;
  const { user } = useAuthenticator((context) => [context.user]);
  const userName = user?.username || "";
  const [workspaceToDelete, setWorkspaceToDelete] = useState(null);

  const navigate = useNavigate();
  const generateGotoWSEdit = (workspace) => (e) => {
    e.preventDefault();
    if (!workspace.permission.write || workspace.freezed) {
      return;
    }
    const midPath = workspace.prepared ? "portfolio" : "properties";
    const path = `/workspace/${workspace.id}/${midPath}/edit`;
    navigate(path);
  };
  const generateGotoWSView = (workspace) => (e) => {
    e.preventDefault();
    if (!workspace.permission.read || !workspace.prepared) {
      return;
    }
    const path = `/workspace/${workspace.id}/portfolio`;
    navigate(path);
  };
  const generateGotoWSReport = (workspace) => (e) => {
    e.preventDefault();
    if (workspace.permission.write && !workspace.freezeDate) {
      navigate(`/workspace/${workspace.id}/report/edit`);
      return;
    }
    if (!workspace.permission.read || !workspace.prepared) {
      return;
    }
    const path = `/workspace/${workspace.id}/report`;
    navigate(path);
  };
  const generateGotoWSDuplicate = (workspace) => (e) => {
    e.preventDefault();
    const path = `/workspace/${workspace.id}/properties/edit`;
    navigate(path);
  };

  const generateDeleteWS = (workspace) => (e) => {
    e.preventDefault();
    console.log("workspace", workspace);
    setWorkspaceToDelete(workspace);
  };

  const gotoCreateNewWS = (e) => {
    e.preventDefault();
    navigate(
      `/workspace/new/properties/edit/customer/${customerId}/pam/${am.id}`
    );
  };

  return (
    <div style={{ marginTop: 118 }}>
      <WSDeletionDialog
        workspace={workspaceToDelete}
        onCancel={() => setWorkspaceToDelete(null)}
        processAfterDeletion={reload}
        pathToNavigatorAfterDeletion={null}
        dismissButtonLabel={null}
      />
      <ListTitle style={{ fontWeight: 400, border: "none" }}>
        <div style={{ padding: "5px 12px" }}>WSリスト</div>
        <div style={{ borderBottom: "1px solid #dddcdb" }}></div>
      </ListTitle>

      <div
        style={{
          fontSize: 12,
          padding: "0 24px",
          display: "flex",
          flexDirection: "column",
          gap: 24,
          backgroundColor: "#EEFAFF",
        }}
      >
        <table>
          <thead style={{ borderBottom: "1px solid #dddcdb" }}>
            <tr style={{ height: 30 }}>
              <th
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  padding: "0 8px",
                }}
              >
                WS名称
              </th>
              <th
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  padding: "0 8px",
                }}
              >
                編集
              </th>
              <th
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  padding: "0 8px",
                }}
              >
                閲覧
              </th>
              <th
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  padding: "0 8px",
                }}
              >
                複製
              </th>
              <th
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  padding: "0 8px",
                }}
              >
                削除
              </th>
              <th
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  padding: "0 8px",
                }}
              >
                担当者
              </th>
              <th
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  padding: "0 8px",
                }}
              >
                作成日
              </th>
              <th
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  padding: "0 8px",
                }}
              >
                更新日
              </th>
              <th
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  padding: "0 8px",
                }}
              >
                レポート閲覧
              </th>
            </tr>
          </thead>

          <tbody>
            {wsList.map((workspace) => {
              const {
                ws,
                freezed,
                viewers,
                ownerName,
                createDate,
                updateDate,
                permission,
              } = workspace;
              return (
                <tr key={`ws-${ws}`} style={{ height: 30 }}>
                  {/* WS名称 */}
                  <td
                    style={{
                      verticalAlign: "middle",
                      verticalAlign: "middle",
                      padding: "0 8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 11,
                      }}
                    >
                      <IconDiv
                        url={`/img/${freezed ? "checked_" : ""}folder_icon.svg`}
                      />
                      <div style={{ whiteSpace: "nowrap" }}>{ws}</div>
                    </div>
                  </td>
                  {/* 編集 */}
                  <td style={{ verticalAlign: "middle", padding: "0 8px" }}>
                    {permission.write && !freezed && (
                      <IconDiv
                        url="/img/pencil_icon.svg"
                        onClick={generateGotoWSEdit(workspace)}
                      />
                    )}
                  </td>
                  {/* 閲覧 */}
                  <td style={{ verticalAlign: "middle", padding: "0 8px" }}>
                    {permission.read && (
                      <IconDiv
                        url={`/img/${
                          freezed ? "freezed_" : ""
                        }ws_view_icon.svg`}
                        onClick={generateGotoWSView(workspace)}
                      />
                    )}
                  </td>
                  {/* 複製 */}
                  <td style={{ verticalAlign: "middle", padding: "0 8px" }}>
                    <IconDiv
                      url={`/img/stack_${freezed ? "" : "light_"}blue.svg`}
                      onClick={generateGotoWSDuplicate(workspace)}
                    />
                  </td>
                  {/* 削除 */}
                  <td style={{ verticalAlign: "middle" }}>
                    <div>
                      {permission.write && !freezed && (
                        <TrashIcon
                          onClick={generateDeleteWS({
                            ...workspace,
                            name: workspace.ws,
                          })}
                          style={{ width: 24, height: 24 }}
                        />
                      )}
                    </div>
                  </td>

                  {/* 担当者 */}
                  <td style={{ verticalAlign: "middle", padding: "0 8px" }}>
                    {ownerName}
                  </td>
                  {/* 作成日 */}
                  <td style={{ verticalAlign: "middle", padding: "0 8px" }}>
                    {formatDate(createDate)}
                  </td>
                  {/* 更新日 */}
                  <td style={{ verticalAlign: "middle", padding: "0 8px" }}>
                    {formatDate(updateDate)}
                  </td>
                  {/* レポート閲覧 */}
                  <td style={{ verticalAlign: "middle", padding: "0 8px" }}>
                    <IconDiv
                      url={`/img/report_${
                        workspace.freezeDate ? "fixed" : "editing"
                      }.svg`}
                      style={{ cursor: "pointer" }}
                      onClick={generateGotoWSReport(workspace)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="spacer" style={{ height: "50px" }} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <OpenButton onClick={gotoCreateNewWS}>
            <img src="/img/create_ws_icon.svg" />
            <div>WS新規作成</div>
          </OpenButton>
        </div>
      </div>
    </div>
  );
};

/**
 * 初期実装
 * 1. WSPropertyのAM部分を流用して、表示系を作成
 * 2. 表示系の範囲で修正、リストが展開できないように
 * 3. フィルタ機能とソート機能を実装
 * 4. 編集モード用のコンポーネントを作成
 * 5. 一旦コンポーネントローカルstateスコープで編集できる機能を実装
 *
 */
const CustomerAM = (props) => {
  // const { ams } = props;
  const { customerId } = props;
  const { user } = useAuthenticator((context) => [context.user]);
  const userId = user.attributes.sub;

  const [ams, setAms] = useState([]);
  // const [selectedAm, setSelectedAm] = useState(ams[0]);
  const [selectedAm, setSelectedAm] = useState(null);
  const [pamToEdit, setPamToEdit] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [assetNames, setAssetNames] = useState({});
  const [wsPropertyList, setWsPropertyList] = useState([]);
  const [wsList, setWSList] = useState([]);
  const [shouldReload, setShouldReload] = useState(true);
  const [assetTypes, setAssetTypes] = useState();

  useEffect(() => {
    if (customerId === null || customerId === undefined) return;
    if (!shouldReload) return;
    getAssetTypes().then((res) => setAssetTypes(res));

    getPAMListByCustomerId(customerId).then((res) => {
      console.log("getPAMListByCustomerId", res);
      setAms(res);
    });

    getAssetNamingByCustomerId(customerId).then((res) => setAssetNames(res));
    getWorkspacePropertiesListByCustomerId(customerId, userId).then(
      (wsProps) => {
        console.log("getWorkspacePropertiesListByCustomerId", wsProps);
        setWsPropertyList(wsProps);
        setShouldReload(false);
      }
    );
  }, [customerId, shouldReload]);

  useEffect(() => {
    const newWSList = wsPropertyList
      .filter(
        ({ am, customerId: wsCustomerId }) =>
          selectedAm?.name === am && wsCustomerId === customerId
      )
      .map((ws) => ({
        ...ws,
        prepared: ws?.ws !== null && ws?.am !== null && ws?.customer !== null,
      }));

    setWSList(newWSList);
  }, [selectedAm, wsPropertyList]);

  const reloadWSList = () => {
    setShouldReload(true);
  };

  useEffect(() => {
    if (!ams || ams.length <= 0) return;

    if (pamToEdit) {
      const index = ams.findIndex(({ id }) => id === pamToEdit.id);
      setSelectedAm(ams[index >= 0 ? index : 0]);
      setPamToEdit(null);
    } else {
      setSelectedAm(ams[0]);
    }
  }, [ams]);

  const addAm = (am) => {
    const amNames = ams?.map(({ name }) => name);
    if (amNames.includes(am.name)) return;
    const baseDate = am.baseDate.replace(/-/g, "/");
    const newAm = { ...am, baseDate, isDeletable: true, isEditable: true };
    setAms([newAm, ...ams]);
    setEditMode(false);
  };

  const updateAm = (am) => {
    const index = ams?.findIndex(({ id }) => id === am.id);
    if (index < 0) return;
    const newAms = [...ams];
    const baseDate = am.baseDate.replace(/-/g, "/");
    const newAm = { ...newAms[index], ...am, baseDate };
    console.log("newAm", newAm);
    newAms[index] = newAm;
    setAms(newAms);
    setEditMode(false);
    // setPamToEdit(null);
  };

  const cancel = () => {
    setEditMode(false);
  };

  // 新規作成処理
  // AMの状態
  //   - AMが参照されているかどうか（変更不可な状態かどうか）
  //   - 保存済みかどうか（= WSで利用できる状態かどうか）

  const amView = editMode ? (
    <CustomerAMEditor
      customerId={customerId}
      ams={ams}
      addAm={addAm}
      cancel={cancel}
    />
  ) : pamToEdit ? (
    <CustomerAMEditor
      customerId={customerId}
      pamToEdit={pamToEdit}
      ams={ams}
      addAm={updateAm}
      cancel={() => {
        setPamToEdit(null);
      }}
    />
  ) : (
    <CustomerAMDetail am={selectedAm} assetNames={assetNames} wsList={wsList} />
  );

  const onClickAddAM = (e) => {
    e.preventDefault();
    setEditMode(true);
  };

  const wsListPart = editMode ? (
    <></>
  ) : (
    <WorkSpaceList
      wsList={wsList}
      am={selectedAm}
      customerId={customerId}
      reload={reloadWSList}
    />
  );

  return (
    <div>
      <ListTitle style={{ padding: 0 }}>
        <div style={{ padding: "5px 12px" }}>政策AMリスト</div>
        <div style={{ borderBottom: "1px solid #dddcdb" }} />
      </ListTitle>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flexGrow: 5 }}>
          <CustomerAMList
            ams={ams}
            selectedAm={selectedAm}
            assetNames={assetNames}
            setSelectedAm={setSelectedAm}
            setPamToEdit={setPamToEdit}
            editMode={editMode}
            onClickAddAM={onClickAddAM}
            extensible={editMode}
            reload={reloadWSList}
            assetTypes={assetTypes}
          />
        </div>
        <div
          style={{
            flexGrow: 5,
            padding: "45px 20px",
          }}
        >
          <div
            style={{
              padding: "37px 55px",
              backgroundColor: "#EEFAFF",
              // maxHeight: `calc(100vh - 280px)`,
              // overflowY: "scroll",
            }}
          >
            <div>{amView}</div>
            {wsListPart}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerAM;

const ListTitle = styled.div`
  ${NotosansjpMediumCloudBurst12px}
  padding: 0 12px;
`;

const SearchOptionButtons = styled.div`
  margin-top: 7px;
  width: 300px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
`;

const RadioButton = styled.input`
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  cursor: pointer;
  .inactive & {
    cursor: initial;
  }
`;

const RadioButtonLabel = styled.label`
  ${NotosansjpNormalNavy10px}
  display: flex;
  align-items: center;
  color: #192e55;
  border-radius: 10px;
  border: 1px solid #dddcdb;
  /* width: 70px; */
  white-space: nowrap;
  padding: 0 10px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  &.checked {
    background: #d2f2ff;
    border: 1px solid #92e0ff;
  }
  .completed &.checked {
    background: #dddcdb;
    border: 1px solid #9fa0a0;
  }
  .completed & {
    cursor: initial;
  }
`;

const ItemList = styled.div`
  /* height: calc(100vh - 380px); */
  /* overflow-y: auto; */

  &::-webkit-scrollbar {
    width: 8px;
    margin-right: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e5eced;
    border-radius: 4px;
    margin-right: 6px;
  }

  /* &.master-items {
    width: 320px;
  }
  &.selected-items {
    width: 340px;
  } */
`;
